import { ApiService } from "./api_service";

export class ServiceManager extends ApiService {

    static  getYears(callback, callback_error) {
        this.executeGet('services/years', callback, callback_error);
    }

    static  getServicesList(year, callback, callback_error) {
        this.executeGet(`services/?year=${year}`, callback, callback_error);
    }

    static  addServiceToCustomer(serviceId, discount, customerId, callback, callback_error) {
        this.executePut(`services/${serviceId}`, {customer_id: customerId, discount: discount}, callback, callback_error);
    }

    static createService(serviceInfos, active_year, callback, callback_error) {
        this.executePut(`services?active_year=${active_year}`, serviceInfos, callback, callback_error);
    }

    static deleteService(serviceId, callback, callback_error) {
        this.executeDelete(`services/${serviceId}`, {}, callback, callback_error);
    }

    static  editService(serviceId, serviceInfos, callback, callback_error) {
        this.executePost(`services/${serviceId}`, serviceInfos, callback, callback_error);
    }

}