import { ApiService } from "./api_service";
import axios from "axios";

export class MemberService extends ApiService {

    static getMembers(callback, callback_error) {
        this.executeGet('customers', callback, callback_error);
    }

    static getMemberBalance(customerId, callback, callback_error) {
        this.executeGet(`customers/${customerId}/balance`, (data) => {
            callback(data.balance);
        }, callback_error);
    }

    static getMember(memberId, callback, callback_error) {
        this.executeGet(`customers/${memberId}`, callback, callback_error);
    }

    static  createMember(memberInfos, callback, callback_error) {
        this.executePut(`customers`, memberInfos, callback, callback_error);
    }
    
    static  deleteMember(memberId, callback, callback_error) {
        this.executeDelete(`customers/${memberId}`, {}, callback, callback_error);
    }

    static  updateMember(memberId, memberInfos, callback, callback_error) {
        this.executePost(`customers/${memberId}`, memberInfos, callback, callback_error);
    }

    static uploadPicture(customerId, file, callback, callback_error) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append("profile_picture", file);
        axios.post(this.apiUrl + `/customers/${customerId}/upload`, formData, config)
            .then((response) => {
				if (response.data["success"] === true) {
					callback(response.data.data);
				}
				else {
					this.handleError(new Error(response.data.error.message), callback_error);
				}
            })
    }

}