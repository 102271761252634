import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import Select from './select';
import { BillService } from '../../../services/bill_service';

class   AddPaymentModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            paymentTypes: [
                {value: "creditcard", display_name: "Carte de crédit", additionalOptions: []},
                {value: "check", display_name: "Chèque", additionalOptions: [{name: "additional_data", type: "text", placeholder: "Numéro de chèque"}]},
                {value: "cash", display_name: "Espèces", additionalOptions: []},
                {value: "transfer", display_name: "Virement", additionalOptions: []},
                {value: "holliday_check", display_name: "Chèques vacances", additionalOptions: []},
                {value: "discount", display_name: "Réduction libre", additionalOptions: []}
                
            ],
            selectedPayment: "-1",
            loading: false,
            values: {amount: (this.props.amount > 0 ? this.props.amount : 0)},
            error: undefined,
            sendEmail: true
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({
            selectedPayment: "-1", loading: false, values: {amount: (newProps.amount > 0 ? newProps.amount : 0)}
        });
    }

    renderAdditionalInputs() {
        const { paymentTypes, selectedPayment } = this.state;
        const additionalInputs = [];

        paymentTypes.forEach(payment => {
            if (payment.value === selectedPayment) {
                payment.additionalOptions.forEach(additionalOption => {
                    additionalInputs.push(
                        <div className="form-group">
                            <input className="form-control" type={additionalOption.type} placeholder={additionalOption.placeholder} name={additionalOption.name} onChange={(e) => {this.handleChange(e)}}/>
                        </div>
                    );
                });
            }
        });

        return additionalInputs;
    }

    renderCreateButton() {
        return (
            <Button variant="outline-primary" disabled={this.formIsIncomplete() || this.state.loading} onClick={() => {this.handleCreate()}}>
                Ajouter le paiement
            </Button>
        );
    }

    formIsIncomplete() {
        return (this.state.values.amount === undefined || this.state.values.amount === "" || this.state.selectedPayment === "-1" ||
                (this.state.selectedPayment === "check" && this.state.values.additional_data === undefined));
    }

    handleChange(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.value;
        this.setState({values: values});
    }

    handleCreate() {
        this.setState({loading: true}, () => {
            if (this.props.amount > 0) {
                BillService.payAll(this.props.member.id, this.state.selectedPayment, this.state.values.additional_data, this.state.sendEmail, () => {
                    this.setState({loading: false}, () => {
                        this.props.onClose();
                    });
                }, (error) => {
                    this.setState({loading: false, error: error.message});
                });    
            }
            else {
                BillService.pay(this.props.bill.id, this.state.values.amount, this.state.selectedPayment, this.state.values.additional_data, this.state.sendEmail, () => {
                    this.setState({loading: false}, () => {
                        this.props.onClose();
                    });
                }, (error) => {
                    this.setState({loading: false, error: error.message});
                });    
            }
        });
    }

    render() {
        if (!this.props.bill && this.props.amount === undefined) {
            return (<div></div>)
        }
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered >
                <Modal.Header closeButton>
                    <Modal.Title> {this.props.bill ? "Paiement: " + this.props.bill.name : "Réglement de la totalité"}  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>Type du paiement</label>
                        <Select options={this.state.paymentTypes} onChange={(paymentMethod) => {this.setState({selectedPayment: paymentMethod})}}/>
                    </div>
                    {this.renderAdditionalInputs()}
                    <div className="form-group">
                        <label>Montant</label>
                        <input className="form-control" value={this.state.values.amount} name="amount" type="number" placeholder="Valeur du paiement" onChange={(e) => {this.handleChange(e)}} step="0.01" disabled={this.props.amount > 0}/>
                    </div>
                    <div className="form-group">
                        <label> Envoi d'un email de confirmation ?</label>
                        <Select default={false} selected={this.state.sendEmail} options={[{value: true, display_name: "Oui"}, {value: false, display_name: "Non"}]} onChange={(newValue) => {this.setState({sendEmail: newValue})}}/>
                    </div>
                    <div>
                        <p className="text-danger">{this.state.error}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    {this.renderCreateButton()}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddPaymentModal;