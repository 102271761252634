import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default class    ConfirmationModal extends Component {

    render() {
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> {this.props.title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.content}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    <Button variant={this.props.variant} onClick={() => {this.props.execute()}}>
                        Confirmer
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}