import React, { Component } from 'react';
import { DocumentsService } from '../../../services/documents_service';
import ConfirmationModal from '../../common/confirmationModal';

const FileIcon = (file) => {
    var fileExt = file.path.split('.').pop();

    if (fileExt === "jpg" || fileExt === "gif") {
        return (<i class="far fa-file-image"></i>);
    }
    else if (fileExt === "pdf") {
        return (<i class="far fa-file-pdf"></i>);
    }
    else {
        return (<i class="fas fa-file"></i>)
    }
}

export class DocumentsList extends Component    {
    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            selectedDeleteFile: undefined,
            selectedDeleteDirectory: undefined
        }
    }

    deleteFile(file) {
        DocumentsService.deleteFile(file.id, () => {
            this.setState({selectedDeleteFile: undefined}, () => {
                this.props.reload();
            });

        }, (error) => {
            this.setState({error: error});
        })
    }

    deleteDirectory(directory) {
        DocumentsService.deleteDirectory(directory.id, () => {
            this.setState({selectedDeleteDirectory: undefined}, () => {
                this.props.reload();
            });

        }, (error) => {
            this.setState({error: error});
        })
    }

    render() {
        const directories = this.props.directories.map((directory) => {
            return (
                <tr className="item-row" >
                    <td onClick={() => this.props.selectDirectory(directory.id)}><i class="fas fa-folder" style={{color: "#3498db"}}></i> {directory.display_name}</td>
                    <td className='text-center'>
                        <button className="btn btn-outline-danger" onClick={() => this.setState({selectedDeleteDirectory: directory})}><i className="far fa-times-circle"></i></button>
                    </td>
                </tr>
            )
        })
        const files = this.props.files.map((file) => {
            return (
                <tr className="item-row">
                    <th onClick={() => {
                                const win = window.open(DocumentsService.renderUrl(file.path), "_blank");
                                win.focus();
                    }}>
                        {FileIcon(file)} {file.display_name}
                    </th>
                    <td className='text-center'>
                        <div className="btn-group" role="group">
                            <button className="btn btn-outline-danger" onClick={() => this.setState({selectedDeleteFile: file})}><i className="far fa-times-circle"></i></button>
                        </div>
                    </td>
                </tr>
            )
        })
        return (
            <table className="table documents">
                <thead className="thead-light">
                    <tr>
                        <th className="thead-title" scope="col">Nom</th>
                        <th className="thead-title" scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.selectedDirectory === undefined ? <></> : <tr className="item-row" onClick={() => this.props.selectDirectory(undefined)}><td><i class="fas fa-long-arrow-alt-left"></i> Retour</td></tr> }
                    {(this.props.selectedDirectory === undefined && !this.props.isLoading) ? directories : <></>}
                    {!this.props.isLoading ? files : <></>}
                    {files.length === 0 && !this.props.isLoading ? <p> Aucun fichier disponible </p> : <></>}
                </tbody>
                <tfoot>
                   <tr><td colSpan="1">{this.props.isLoading ? <i className="fa fa-circle-o-notch fa-spin"></i> : <></>}</td></tr>
                </tfoot>
                <ConfirmationModal
                    active={this.state.selectedDeleteFile !== undefined}
                    variant={"danger"}
                    title={`Êtes-vous sûr de vouloir supprimer le document ${this.state.selectedDeleteFile !== undefined ? this.state.selectedDeleteFile.display_name : ""}?`}
                    content={`Le fichier sera supprimé définitivement ${this.state.error !== undefined ? this.state.error : ""}`}
                    onClose={() => {this.setState({selectedDeleteFile: undefined})}}
                    execute={() => {this.deleteFile(this.state.selectedDeleteFile)}}
                />
                <ConfirmationModal
                    active={this.state.selectedDeleteDirectory !== undefined}
                    variant={"danger"}
                    title={`Êtes-vous sûr de vouloir supprimer le dossier ${this.state.selectedDeleteDirectory !== undefined ? this.state.selectedDeleteDirectory.display_name : ""}?`}
                    content={`Le dossier et ses fichiers seront supprimés définitivement ${this.state.error !== undefined ? this.state.error : ""}`}
                    onClose={() => {this.setState({selectedDeleteDirectory: undefined})}}
                    execute={() => {this.deleteDirectory(this.state.selectedDeleteDirectory)}}
                />
            </table>
        )    
    }
}
