import React, { Component, useState }  from 'react';
import { ServiceManager } from '../../services/service_manager';
import { Container } from '../containers/container';
import { PaymentsGraph } from './components/payments_graph';

class ActiveYearSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            availableYears: []
        } 
    }

    componentDidMount() {
        this.fetchYears()
    } 

    fetchYears() {
        ServiceManager.getYears((years) => {
            if (years.length > 0) {
                console.log(years)
                this.setState({availableYears: years}, () => {
                    this.props.selectYear(years[0].active_year);
                });
            }
        }, (error) => {})
    }
    
    render() {
        const options = this.state.availableYears.map(availableYear => {
            return (<option value={availableYear.active_year}>{availableYear.active_year}</option>)
        });
        return (
            <select className="form-control" value={this.props.selectedYear} onChange={e => this.props.selectYear(e.target.value)}>
                {options}
            </select>
        )    
    }
}

export const StatsPage = () => {
    const [activeYear, setActiveYear] = useState(-1)
    
    return (
        <div>
            <h1> Statistiques </h1>
            <div className="row">
                <Container className="col-10 offset-1">
                    <ActiveYearSelector selectYear={(year) => setActiveYear(year)} selectedYear={activeYear}/>
                    <PaymentsGraph activeYear={activeYear}/>
                </Container>
            </div>
        </div>
    )
}