import { ApiService } from "./api_service";

export class BoatService extends ApiService {
    
    static getBoats(memberId, callback, callback_error) {
        this.executeGet(`boats/r/${memberId}`, callback, callback_error);
    }

    static  getParkedBoats(year, callback, callback_error) {
        this.executeGet(`boats/parked_boats?year=${year}`, callback, callback_error);
    }

    static  deleteBoat(boatId, callback, callback_error) {
        this.executeDelete(`boats/${boatId}`, {}, callback, callback_error);
    }

    static  createBoat(customerId, boat_infos, callback, callback_error) {
        this.executePut(`boats/${customerId}`, boat_infos, callback, callback_error);
    }

    static  updateBoat(customerId, boat_infos, callback, callback_error) {
        this.executePost(`boats/${customerId}`, boat_infos, callback, callback_error);
    }

    static  parkBoat(boatId, parkingInfos, callback, callback_error) {
        this.executePost(`boats/${boatId}/park`, parkingInfos, callback, callback_error);
    }

}