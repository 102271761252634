import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './members_list.css';
import { FileService } from '../../../services/file_service';

class   MembersList extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            order_by: {
                key: "email",
                asc: true
            }
        }
    }

    setOrderBy(key) {
        if (this.state.order_by.key === key) {
            this.setState({order_by: {
                key: key,
                asc: !this.state.order_by.asc
            }});
        }
        else {
            this.setState({order_by: {
                key: key,
                asc: false
            }});
        }
    }

    getMemberFullName(member) {
        return `${member.first_name} ${member.last_name}`
    }

    renderMemberState(state) {
        if (state === "member") {
            return (<div className="badge badge-success badge-outlined"> Membre </div>);
        }
        else if (state === "associate_member") {
            return (<div className="badge badge-success badge-outlined"> Membre associé</div>);
        }
        else if (state === "temporary") {
            return (<div className="badge badge-warning badge-outlined"> Passager </div>);
        }
        else if (state === "old_member") {
            return (<div className="badge badge-danger badge-outlined"> Ancien membre </div>);
        }
        else if (state === "admin_member") {
            return (<div className="badge badge-secondary badge-outlined"> Membre au bureau </div>);
        }
        else {
            return (<div className="badge badge-primary badge-outlined"> Nouveau </div>);
        }
    }

    getMembersSorted() {
        const { members } = this.props;

        members.sort((a, b) => {
            if (this.state.order_by.asc) {
                return (a[this.state.order_by.key] > b[this.state.order_by.key]);
            }
            return (a[this.state.order_by.key] < b[this.state.order_by.key]);
        });
        return members
    }

    renderMembersList() {
        return (this.getMembersSorted().map(member => {
            return (
                <tr key={member.id}>
                    <th scope="row">
                        <div className="fullname-container">
                            <img className="member-profile-picture" src={FileService.renderMemberProfilePicture(member)} alt="profile"/> {member.first_name} {member.last_name} {member.last_active_year ? <>({member.last_active_year}) </>: <></>}  
                        </div>
                    </th>
                    <td>{member.email}</td>
                    <td>{member.mobile_phone}</td>
                    <td><span className={(member.solde === 0 ? "solde" : (member.solde > 0 ? "solde bonus" : "solde due"))}>{member.solde !== 0 ? (member.solde > 0 ? "+" : ""): ""}{member.solde}</span> €</td>
                    <td>{this.renderMemberState(member.state)}</td>
                    <td><button className="btn btn-light-outlined" title="Aller à" onClick={() => {this.props.history.push(`/members/${member.id}`)}}><i className="fas fa-arrow-right"></i></button></td>
                </tr>
            )
        }));
    }

    renderKey(displayName, key) {
        if (this.state.order_by.key === key) {
            return (
                <th className="thead-title clickable" scope="col" onClick={() => {this.setOrderBy(key)}}>{displayName} <i className={"fa " + (!this.state.order_by.asc ? "fa-arrow-down" : "fa-arrow-up")}></i></th>
            )
        }
        return (
            <th className="thead-title clickable" scope="col" onClick={() => {this.setOrderBy(key)}}>{displayName}</th>
        );
    }

    render() {
        const membersList = this.renderMembersList();

        if (this.props.loading) {
            return (
                <div className="members-list-table loading">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        return (
            <table id="member-list" className="table members-list-table">
                <thead className="thead-light">
                    <tr>
                        {this.renderKey("Nom complet", "last_name")}
                        {this.renderKey("E-mail", "email")}
                        <th className="thead-title" scope="col">Téléphone</th>
                        {this.renderKey("Solde", "solde")}
                        {this.renderKey("Statut", "state")}
                        <th className="thead-title" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {membersList}
                </tbody>
            </table>
        )
    }
}

export default withRouter(MembersList);