import React, { Component } from 'react';
import {Toast} from 'react-bootstrap';

import './notifications.css';

export class	ErrorNotification extends Component {

	constructor(props) {
		super(props);

		this.state = {show: false}
	}

	componentWillReceiveProps(newProps) {
		this.setState({show: true});
	}

	componentWillMount() {
		this.setState({show: true});
	}

	render() {
		return (
			<div aria-live="polite" aria-atomic="true"
				style={{
					position: 'absolute',
					minHeight: '100px',
					top: 25,
					right: 25
				}}
				>
				<Toast className="error"
					style={{
					position: 'absolute',
					top: 0,
					right: 0,
					}}
					onClose={() => this.setState({show: false})} show={this.state.show} delay={3000} autohide
				>
					<Toast.Header>
						<strong className="mr-auto">Une erreur s'est produite...</strong>
					</Toast.Header>
					<Toast.Body>{this.props.errorMessage}</Toast.Body>
				</Toast>
				</div>
		);
	}
}

export class	SuccessNotification extends Component {

	constructor(props) {
		super(props);

		this.state = {show: false}
	}

	componentWillReceiveProps(newProps) {
		this.setState({show: true});
	}

	componentWillMount() {
		this.setState({show: true});
	}

	render() {
		return (
			<div aria-live="polite" aria-atomic="true"
				style={{
					position: 'absolute',
					minHeight: '100px',
					top: 25,
					right: 25
				}}
				>
				<Toast className="success"
					style={{
					position: 'absolute',
					top: 0,
					right: 0,
					}}
					onClose={() => this.setState({show: false})} show={this.state.show} delay={3000} autohide
				>
					<Toast.Header>
					<strong className="mr-auto">Parfait !</strong>
					</Toast.Header>
					<Toast.Body>{this.props.successMessage}</Toast.Body>
				</Toast>
				</div>
		);
	}
}