import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import HomePage from '../components/home_page/home_page';
import MembersPage from '../components/members_page/members_page';
import Sidenav from '../components/sidenav/sidenav';
import MemberPage from '../components/member_page/member_page';
import HarborPage from '../components/harbor_page/harbor_page';
import AdministrationPage from '../components/administration_page/administration_page';

import './admin.css';
import { StatsPage } from '../components/stats_page/stats_page';
import { WebsitePagesRouter } from '../components/website_pages';

const backgroundContainer = {
    backgroundColor: `#191c21`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
};

export class	AdminRouter extends Component {

	constructor(props) {
		super(props);

		this.state = {
            hover: false
        }
	}

	toggleHover() {
        this.setState({hover: !this.state.hover})
	}

	render() {
		return (
			<div id="admin_page_container">
				<div id="sidenav_container" style={backgroundContainer} onMouseEnter={() => {this.toggleHover()}} onMouseLeave={() => {this.toggleHover()}}>
					<div id="sidenav_inner_container">
						<Sidenav hover={this.state.hover}/>
					</div>
				</div>
				<div id="content">
					<Route exact path="/" component={HomePage} />
					<Route exact path="/members" component={MembersPage} />
					<Route exact path="/harbor" component={HarborPage} />
					<Route exact path="/administration" component={AdministrationPage} />
					<Route path="/members/:memberId" component={MemberPage}/>
					<Route exact path="/stats" component={StatsPage} />
					<Route path="/website" component={WebsitePagesRouter}/>
				</div>
			</div>
		);
	}
	
}
