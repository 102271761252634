import React, { Component } from 'react';

import './harbor_page.css';
import Harbor from './components/harbor';

class	HarborPage extends Component {

	render() {
		return (
			<div>
				<h1> Le port </h1>
				<Harbor/>
			</div>
		);
	}
}

export default HarborPage;