import React, { Component } from 'react';

import './container.css';

export class    Container extends Component {

    render() {
        const style = {

        }
        if (this.props.tintColor) {
            style.borderTop = "4px solid " + this.props.tintColor;
        }
        return (
            <div className={this.props.className}>
                <div className={"cng-container"} style={style}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}