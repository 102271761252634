import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { AdminService } from '../../../services/admin_service';

class   AddAdministratorModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            values: {
                email: undefined,
                first_name: undefined,
                last_name: undefined
            },
            error: undefined
        }
    }

    handleInputChange(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.value;
        this.setState({values: values});
    }

    renderBody() {
        return (
            <div>
                <div className="form-group">
                    <label>Email</label>
                    <input value={this.state.values.email} className="form-control" type="text" placeholder="Adresse email" name="email" onChange={(e) => {this.handleInputChange(e)}}/>
                </div>
                <div className="row">
                    <div className="form-group col-6">
                        <label>Prénom</label>
                        <input value={this.state.values.first_name} className="form-control" type="text" placeholder="Prénom" name="first_name" onChange={(e) => {this.handleInputChange(e)}}/>
                    </div>
                    <div className="form-group col-6">
                        <label>Nom</label>
                        <input value={this.state.values.last_name} className="form-control" type="text" placeholder="Nom" name="last_name" onChange={(e) => {this.handleInputChange(e)}}/>
                    </div>
                </div>
            </div>
        )
    }

    formIsIncomplete() {
        return this.state.values.first_name === undefined || this.state.values.first_name === "" ||
                this.state.values.last_name === undefined || this.state.values.last_name === "" ||
                this.state.values.email === undefined || this.state.values.email === "";
    }

    addAdministrator() {
        this.setState({loading: true}, () => {
            AdminService.createAdministrator(this.state.values, () => {
                this.setState({loading: false}, () => {
                    this.props.onClose();
                });
            }, (error) => {
                this.setState({loading: false, error: error.message});
            });    
        });
    }

    render() {
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> Ajouter un administrateur </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderBody()}
                    <p className="text-danger">{this.state.error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => {this.addAdministrator()}} disabled={this.formIsIncomplete() || this.state.loading}>
                        Ajouter l'administrateur
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddAdministratorModal;