import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { ServiceManager } from '../../../services/service_manager';

class   AddServiceModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            values: {
                name: undefined,
                price: undefined,
                active_year: this.props.selectedYear
            },
            error: undefined
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({loading: false, values: {name: undefined, price: undefined, active_year: newProps.selectedYear}}, () => {
            if (this.props.edit && newProps.service) {
                const { values } = this.state;

                values["name"] = newProps.service.name;
                values["price"] = newProps.service.price;

                this.setState({values: values});
            }
        });
    }

    handleInputChange(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.value;
        this.setState({values: values});
    }

    renderBody() {
        return (
            <div>
                <div className="form-group">
                    <label>Nom du service</label>
                    <input value={this.state.values.name} className="form-control" type="text" placeholder="Nom du service" name="name" onChange={(e) => {this.handleInputChange(e)}}/>
                </div>
                <div className="form-group">
                    <label>Prix</label>
                    <input value={this.state.values.price} className="form-control" type="number" step="0.01" placeholder="Prix du service" name="price" onChange={(e) => {this.handleInputChange(e)}}/>
                </div>
            </div>
        )
    }

    formIsIncomplete() {
        return this.state.values.name === undefined || this.state.values.name === "" ||
                this.state.values.price === undefined || this.state.values.price <= 0;
    }

    addService() {
        this.setState({loading: true}, () => {
            if (this.props.edit) {
                ServiceManager.editService(this.props.service.id, this.state.values, () => {
                    this.setState({loading: false}, () => {
                        this.props.onClose();
                    });
                }, (error) => {
                    this.setState({loading: false, error: error.message});
                });    
            }
            else {
                ServiceManager.createService(this.state.values, this.props.selectedYear, () => {
                    this.setState({loading: false}, () => {
                        this.props.onClose();
                    });
                }, (error) => {
                    this.setState({loading: false, error: error.message});
                });    
            }
        });
    }

    render() {
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> Ajouter un service ({this.props.selectedYear}) </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderBody()}
                    <p className="text-danger">{this.state.error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => {this.addService()}} disabled={this.formIsIncomplete() || this.state.loading}>
                        Ajouter le service
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddServiceModal;