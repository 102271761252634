import React, { Component } from 'react';

import './data_tables.css';
import AddPaymentModal from './add_payment_modal';
import AddBoatModal from './add_boat_modal';
import ConfirmationModal from '../../common/confirmationModal';
import AddBadgeModal from './add_badge_modal';
import AddLicenseModal from './add_license_modal';
import { BillService } from '../../../services/bill_service';
import { BoatService } from '../../../services/boat_service';
import { BadgeService } from '../../../services/badge_service';
import { LicenseService } from '../../../services/license_service';
import { FileService } from '../../../services/file_service';

export class   BillsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPaymentModal: false,
            selectedBill: undefined,
            showDeleteBillModal: false,
            selectedBillToDelete: undefined,
            showPayEverythingModal: false
        }
    }

    renderTableHead() {
        return (
            <tr>
                <th className="thead-title" scope="col">ID</th>
                <th className="thead-title" scope="col">Nom</th>
                <th className="thead-title" scope="col">Prix</th>
                <th className="thead-title" scope="col">Reste dû</th>
                <th className="thead-title" scope="col">Date d'emission</th>
                <th className="thead-title" scope="col">Etat</th>
                <th className="thead-title" scope="col">Actions</th>
            </tr>
        )
    }

    renderBillState(data) {
        if (this.renderDueValue(data) === 0) {
            return (
                <span className="badge badge-pill badge-success badge-outlined">Payé</span>
            );    
        }
        else if (data.paid === 0 || data.paid === null) {
            return (
                <span className="badge badge-pill badge-danger badge-outlined">Non payé</span>
            )                
        }
        return (
            <span className="badge badge-pill badge-warning badge-outlined">Accompte</span>
        )
    }

    renderDueValue(data) {
        if (data.price - data.paid < 0) {
            return 0;
        }
        return data.price - data.paid;
    }

    renderActions(data) {
        const buttons = [];

        if (this.renderDueValue(data) > 0) {
            buttons.push(<button className="btn btn-outline-primary" title="Ajouter un paiement" onClick={() => {this.setState({showPaymentModal: true, selectedBill: data})}}><i className="fas fa-credit-card"></i></button>);
        }
        buttons.push(<button className="btn btn-outline-danger" title="Supprimer la facture" onClick={() => {this.setState({showDeleteBillModal: true, selectedBillToDelete: data})}}><i className="far fa-times-circle"></i></button>)
        return (
            <div className="btn-group">
                {buttons}
            </div>
        );
    }

    renderTableBody() {
        return (
            this.props.data.map(data => {
                return (
                    <tr key={data.id}>
                        <th scope="row">{data.id}</th>
                        <td>{data.name}</td>
                        <td>{data.price} €</td>
                        <td>{this.renderDueValue(data)} €</td>
                        <td>{new Date(data.created_at).toLocaleDateString("fr")}</td>
                        <td>{this.renderBillState(data)}</td>
                        <td style={{textAlign: 'center'}}>{this.renderActions(data)}</td>
                    </tr>
                )
            })
        )
    }

    deleteBill() {
        BillService.deleteBill(this.state.selectedBillToDelete.id, () => {
            this.setState({showDeleteBillModal: false, selectedBillToDelete: undefined}, () => {
                this.props.updateDatas();
            });
        }, (error) => {
        });
    }

    renderTableFooter() {
        let dataFormatted = {
            total_price: 0,
            due: 0
        }

        this.props.data.forEach(data => {
            dataFormatted.total_price += data.price;
            dataFormatted.due += this.renderDueValue(data);
        });

        return (
            <tr>
                <th className="thead-title">Totaux</th>
                <th className="thead-title"></th>
                <th className="thead-title">{dataFormatted.total_price} €</th>
                <th className="thead-title">{dataFormatted.due} €</th>
                <th className="thead-title"></th>
                <th className="thead-title"></th>
                <th className="thead-title">
                    {dataFormatted.due > 0 ? <button className="btn btn-outline-warning" onClick={() => {this.setState({showPayEverythingModal: true})}}><i className="fas fa-credit-card"></i> Régler la totalité</button> : <></>}
                    <AddPaymentModal active={this.state.showPayEverythingModal} member={this.props.member} amount={dataFormatted.due} onClose={() => {this.setState({showPayEverythingModal: false}, this.props.updateDatas)}}/>
                </th>
            </tr>
        )
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if (!this.props.data || this.props.data.length === 0) {
            return (
                <div className="table-container">
                    <p> {this.props.tableIsEmpty} </p>
                </div>
            )
        }
        return (
            <table className="table table-container">
                <thead className="thead-light">
                    {this.renderTableHead()}
                </thead>
                <tbody>
                    {this.renderTableBody()}
                </tbody>
                <tfoot>
                    {this.renderTableFooter()}
                </tfoot>
                <AddPaymentModal bill={this.state.selectedBill} active={this.state.showPaymentModal} onClose={() => {this.setState({showPaymentModal: false, selectedBill: undefined}, () => {this.props.updateDatas()})}}/>
                <ConfirmationModal
                    active={this.state.showDeleteBillModal}
                    variant={"danger"}
                    title="Êtes-vous sûr de vouloir supprimer cette facture ?"
                    content={"Les paiements associés seront également supprimés, cette action est irréversible"}
                    onClose={() => {this.setState({showDeleteBillModal: false, selectedBillToDelete: undefined})}}
                    execute={() => {this.deleteBill()}}
                />
            </table>
        )
    }
}

export class   PaymentsTable extends Component {

    renderTableHead() {
        return (
            <tr>
                <th className="thead-title" scope="col">ID facture</th>
                <th className="thead-title" scope="col">Montant</th>
                <th className="thead-title" scope="col">Type</th>
                <th className="thead-title" scope="col">Données additionelles</th>
                <th className="thead-title" scope="col">Date de réception</th>
            </tr>
        )
    }

    renderPaymentType(type) {
        switch (type) {
            case "creditcard":
                return "Carte de crédit";
            case "check":
                return "Chèque";
            case "cash":
                return "Espèces"
            case "transfer":
                return "Virement"
            case "holliday_check":
                return "Chèques vacances"
            default:
                return type;
        }
    }

    renderTableFooter() {
        let dataFormatted = {
            amount: 0,
        }

        this.props.data.forEach(data => {
            dataFormatted.amount += data.amount;
        });

        return (
            <tr>
                <th className="thead-title">Totaux</th>
                <th className="thead-title">{dataFormatted.amount} €</th>
                <th className="thead-title"></th>
                <th className="thead-title"></th>
                <th className="thead-title"></th>
            </tr>
        )
    }

    renderTableBody() {
        return (
            this.props.data.map(data => {
                return (
                    <tr key={data.id}>
                        <th scope="row">{data.bill_id}</th>
                        <td>{data.amount} €</td>
                        <td>{this.renderPaymentType(data.type)}</td>
                        <td>{data.additional_data ? data.additional_data : "/"}</td>
                        <td>{new Date(data.created_at).toLocaleDateString("fr")}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if (!this.props.data || this.props.data.length === 0) {
            return (
                <div className="table-container">
                    <p> {this.props.tableIsEmpty} </p>
                </div>
            )
        }
        return (
            <table className="table table-container">
                <thead className="thead-light">
                    {this.renderTableHead()}
                </thead>
                <tbody>
                    {this.renderTableBody()}
                </tbody>
                <tfoot>
                    {this.renderTableFooter()}
                </tfoot>
            </table>
        )
    }
}

export class   BoatsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteBoatModal: false,
            selectedBoatToDelete: undefined,
            showEditPontoonModal: false,
            showEditBoatModal: false,
            selectedBoatToEdit: undefined
        }
    }

    renderTableHead() {
        return (
            <tr>
                <th className="thead-title" scope="col">Nom</th>
                <th className="thead-title" scope="col">Place</th>
                <th className="thead-title" scope="col">Constructeur</th>
                <th className="thead-title" scope="col">Modèle</th>
                <th className="thead-title" scope="col">Dimensions</th>
                <th className="thead-title" scope="col">Actions</th>
            </tr>
        );
    }

    deleteBoat() {
        const { selectedBoatToDelete } = this.state;

        if (selectedBoatToDelete !== undefined) {
            BoatService.deleteBoat(selectedBoatToDelete, () => {
                this.setState({selectedBoatToDelete: undefined, showDeleteBoatModal: false}, () => {
                    this.props.updateDatas();
                });
            }, (error) => {
                this.props.updateDatas();
            });    
        }
    }

    renderActions(data) {
        const buttons = []

        buttons.push(<button className="btn btn-outline-success" title="Changer la place de ponton" onClick={() => {this.setState({showEditPontoonModal: true, selectedBoatToEdit: data})}}><i className="fas fa-map-marker-alt"></i></button>);
        buttons.push(<button className="btn btn-outline-primary" title="Modifier le bateau" onClick={() => {this.setState({showEditBoatModal: true, selectedBoatToEdit: data})}}><i className="fas fa-cog"></i></button>);
        buttons.push(<button className="btn btn-outline-danger" title="Supprimer le bateau" onClick={() => {this.setState({showDeleteBoatModal: true, selectedBoatToDelete: data.id})}}><i className="far fa-times-circle"></i></button>);
        return (
            <div className="btn-group">
                {buttons}
            </div>
        );
    }
    
    renderPontoon(data) {
        if (data.pontoon !== undefined && data.quatway_number !== undefined) {
            if (data.active_year < new Date().getFullYear()) {
                return (<span className="text-danger">{data.pontoon} {data.quatway_number} ({data.active_year})</span>);
            }
            return (<span className="text-success">{data.pontoon} {data.quatway_number} ({data.active_year})</span>);
        }
        return (<span className="text-danger">Hors de l'eau</span>);
    }

    renderTableBody() {
        return (
            this.props.data.map(data => {
                return (
                    <tr key={data.id}>
                        <th scope="row">{data.name}</th>
                        <td>{this.renderPontoon(data)}</td>
                        <td>{data.brand}</td>
                        <td>{data.model}</td>
                        <td>{data.length}m x {data.width}m</td>
                        <td style={{textAlign: 'center'}}>{this.renderActions(data)}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if (!this.props.data || this.props.data.length === 0) {
            return (
                <div className="table-container">
                    <p> {this.props.tableIsEmpty} </p>
                </div>
            )
        }
        return (
            <table className="table table-container">
                <thead className="thead-light">
                    {this.renderTableHead()}
                </thead>
                <tbody>
                    {this.renderTableBody()}
                </tbody>
                <ConfirmationModal
                    active={this.state.showDeleteBoatModal}
                    variant={"danger"}
                    title="Êtes-vous sûr de vouloir supprimer ce bateau ?"
                    content={"L'historique des places de ponton sera aussi supprimé"}
                    onClose={() => {this.setState({showDeleteBoatModal: false, selectedBoatToDelete: undefined})}}
                    execute={() => {this.deleteBoat()}}
                />
                <AddBoatModal boat={this.state.selectedBoatToEdit} editBoat={true} member={this.props.member} active={this.state.showEditBoatModal} onClose={() => {this.setState({showEditBoatModal: false}, () => {this.props.updateDatas()})}} />
                <AddBoatModal boat={this.state.selectedBoatToEdit} editPontoon={true} member={this.props.member} active={this.state.showEditPontoonModal} onClose={() => {this.setState({showEditPontoonModal: false}, () => {this.props.updateDatas()})}} />
            </table>
        )
    }
}

export class   BadgesTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteBadgeModal: false,
            selectedBadgeToDelete: undefined,
            showEditModal: false,
            selectedToEdit: undefined
        }
    }

    renderTableHead() {
        return (
            <tr>
                <th className="thead-title" scope="col">ID</th>
                <th className="thead-title" scope="col">Numéro du badge</th>
                <th className="thead-title" scope="col">Option vélo</th>
                <th className="thead-title" scope="col">Ajouté le</th>
                <th className="thead-title" scope="col">Actions</th>
            </tr>
        );
    }

    deleteBadge() {
        const { selectedBadgeToDelete } = this.state;

        if (selectedBadgeToDelete !== undefined) {
            BadgeService.deleteBadge(this.props.member.id, selectedBadgeToDelete, () => {
                this.setState({selectedBadgeToDelete: undefined, showDeleteBadgeModal: false}, () => {
                    this.props.updateDatas();
                });
            }, (error) => {
                this.props.updateDatas();
            });
        }
    }

    renderActions(data) {
        const buttons = []

        buttons.push(<button className="btn btn-outline-primary" title="Modifier le badge" onClick={() => {this.setState({showEditModal: true, selectedToEdit: data})}}><i className="fas fa-cog"></i></button>);
        buttons.push(<button className="btn btn-outline-danger" title="Supprimer le badge" onClick={() => {this.setState({showDeleteBadgeModal: true, selectedBadgeToDelete: data.id})}}><i className="far fa-times-circle"></i></button>)
        return (
            <div className="btn-group">
                {buttons}
            </div>
        );
    }
    
    renderTableBody() {
        return (
            this.props.data.map(data => {
                return (
                    <tr key={data.id}>
                        <th scope="row">{data.id}</th>
                        <td>{data.badge_id}</td>
                        <td>{data.with_bike ? "Oui" : "Non"}</td>
                        <td>{new Date(data.created_at).toLocaleDateString("fr")}</td>
                        <td style={{textAlign: 'center'}}>{this.renderActions(data)}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if (!this.props.data || this.props.data.length === 0) {
            return (
                <div className="table-container">
                    <p> {this.props.tableIsEmpty} </p>
                </div>
            )
        }
        return (
            <table className="table table-container">
                <thead className="thead-light">
                    {this.renderTableHead()}
                </thead>
                <tbody>
                    {this.renderTableBody()}
                </tbody>
                <tfoot>
                    <AddBadgeModal member={this.props.member} badge={this.state.selectedToEdit} active={this.state.showEditModal} onClose={() => {this.setState({showEditModal: false}, () => {this.props.updateDatas()})}} />
                </tfoot>
                <ConfirmationModal
                    active={this.state.showDeleteBadgeModal}
                    variant={"danger"}
                    title="Êtes-vous sûr de vouloir supprimer ce badge ?"
                    content={"Le badge ne sera plus rattaché à ce membre"}
                    onClose={() => {this.setState({showDeleteBadgeModal: false, selectedBadgeToDelete: undefined})}}
                    execute={() => {this.deleteBadge()}}
                />
            </table>
        )
    }
}

export class   LicenseTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteLicenseModal: false,
            selectedLicenseToDelete: undefined,
            showEditModal: false,
            selectedToEdit: undefined
        }
    }

    renderTableHead() {
        return (
            <tr>
                <th className="thead-title" scope="col">N° de licence</th>
                <th className="thead-title" scope="col">Nom complet du porteur</th>
                <th className="thead-title" scope="col">Date d'expiration</th>
                <th className="thead-title" scope="col">Actions</th>
            </tr>
        );
    }

    deleteLicense() {
        const { selectedLicenseToDelete } = this.state;

        if (selectedLicenseToDelete !== undefined) {
            LicenseService.deleteLicense(selectedLicenseToDelete, () => {
                this.setState({selectedLicenseToDelete: undefined, showDeleteLicenseModal: false}, () => {
                    this.props.updateDatas();
                });
            }, (error) => {
                this.props.updateDatas();
            });
        }
    }

    renderActions(data) {
        const buttons = []

        buttons.push(<button className="btn btn-outline-primary" title="Modifier la licence" onClick={() => {this.setState({showEditModal: true, selectedToEdit: data})}}><i className="fas fa-cog"></i></button>);
        buttons.push(<button className="btn btn-outline-danger" title="Supprimer la licence" onClick={() => {this.setState({showDeleteLicenseModal: true, selectedLicenseToDelete: data.id})}}><i className="far fa-times-circle"></i></button>)
        return (
            <div className="btn-group">
                {buttons}
            </div>
        );
    }

    renderExpirationDate(data) {
        if (new Date(data.expire_at) < new Date()) {
            return (
                <p className="text-danger">{new Date(data.expire_at).toLocaleDateString("fr")}</p>
            )
        }
        return (<p className="text-success">{new Date(data.expire_at).toLocaleDateString("fr")}</p>);
    }
    
    renderTableBody() {
        return (
            this.props.data.map(data => {
                return (
                    <tr key={data.id}>
                        <th scope="row">{data.license_id}</th>
                        <td>{data.holder_first_name} {data.holder_last_name}</td>
                        <td className="font-weight-bold">{this.renderExpirationDate(data)}</td>
                        <td style={{textAlign: 'center'}}>{this.renderActions(data)}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if (!this.props.data || this.props.data.length === 0) {
            return (
                <div className="table-container">
                    <p> {this.props.tableIsEmpty} </p>
                </div>
            )
        }
        return (
            <table className="table table-container">
                <thead className="thead-light">
                    {this.renderTableHead()}
                </thead>
                <tbody>
                    {this.renderTableBody()}
                </tbody>
                <tfoot>
                    <AddLicenseModal member={this.props.member} license={this.state.selectedToEdit} active={this.state.showEditModal} onClose={() => {this.setState({showEditModal: false}, () => {this.props.updateDatas()})}} />
                </tfoot>
                <ConfirmationModal
                    active={this.state.showDeleteLicenseModal}
                    variant={"danger"}
                    title="Êtes-vous sûr de vouloir supprimer cette licence ?"
                    content={"Cette licence ne sera plus rattaché à ce membre."}
                    onClose={() => {this.setState({showDeleteLicenseModal: false, selectedLicenseToDelete: undefined})}}
                    execute={() => {this.deleteLicense()}}
                />
            </table>
        )
    }
}

export class   FilesTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteFileModal: false,
            selectedFileToDelete: undefined
        }
    }

    renderTableHead() {
        return (
            <tr>
                <th className="thead-title" scope="col">ID</th>
                <th className="thead-title" scope="col">Nom du fichier</th>
                <th className="thead-title" scope="col">Créé le</th>
                <th className="thead-title" scope="col">Actions</th>
            </tr>
        );
    }

    deleteFile() {
        const { selectedFileToDelete } = this.state;

        if (selectedFileToDelete !== undefined) {
            FileService.deleteFile(this.props.member.id, selectedFileToDelete, () => {
                this.setState({selectedFileToDelete: undefined, showDeleteFileModal: false}, () => {
                    this.props.updateDatas();
                });
            }, (error) => {
                this.props.updateDatas();
            });
        }
    }

    renderActions(data) {
        const buttons = []

        buttons.push(<a key={"open_file"} className="btn btn-outline-primary" title="Voir le fichier" target="_blank" rel="noopener noreferrer" href={FileService.generateFileUrl(data.path)}><i className="fas fa-eye"></i></a>)
        buttons.push(<button key={"delete_file"} className="btn btn-outline-danger" title="Supprimer le fichier" onClick={() => {this.setState({showDeleteFileModal: true, selectedFileToDelete: data.id})}}><i className="far fa-times-circle"></i></button>)
        return (
            <div className="btn-group">
                {buttons}
            </div>
        );
    }

    renderExpirationDate(data) {
        if (new Date(data.expire_at) < new Date()) {
            return (
                <p className="text-danger">{new Date(data.expire_at).toLocaleDateString("fr")}</p>
            )
        }
        return (<p className="text-success">{new Date(data.expire_at).toLocaleDateString("fr")}</p>);
    }
    
    renderTableBody() {
        return (
            this.props.data.map(data => {
                return (
                    <tr key={data.id}>
                        <th scope="row">{data.id}</th>
                        <td>{data.name}</td>
                        <td className="font-weight-bold">{new Date(data.created_at).toLocaleDateString("fr")}</td>
                        <td style={{textAlign: 'center'}}>{this.renderActions(data)}</td>
                    </tr>
                )
            })
        )
    }

    render() {
        if (this.props.loading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if (!this.props.data || this.props.data.length === 0) {
            return (
                <div className="table-container">
                    <p> {this.props.tableIsEmpty} </p>
                </div>
            )
        }
        return (
            <table className="table table-container">
                <thead className="thead-light">
                    {this.renderTableHead()}
                </thead>
                <tbody>
                    {this.renderTableBody()}
                </tbody>
                <ConfirmationModal
                    active={this.state.showDeleteFileModal}
                    variant={"danger"}
                    title="Êtes-vous sûr de vouloir supprimer ce document ?"
                    content={"Ce document ne sera plus lié à ce membre."}
                    onClose={() => {this.setState({showDeleteFileModal: false, selectedFileToDelete: undefined})}}
                    execute={() => {this.deleteFile()}}
                />
            </table>
        )
    }
}