import React, { Component } from 'react';
import * as moment from 'moment';
import { Modal, Button } from 'react-bootstrap';

import { LicenseService } from '../../../services/license_service';


class   AddLicenseModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            values: {
                license_id: this.props.license ? this.props.license.license_id : undefined,
                first_name: this.props.license ? this.props.license.holder_first_name : undefined,
                last_name: this.props.license ? this.props.license.holder_last_name : undefined,
                expire_at: this.props.license ? moment(new Date(this.props.license.expire_at)).format("YYYY-MM-DD") : undefined
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({loading: false, values: {
            license_id: newProps.license ? newProps.license.license_id : undefined,
            first_name: newProps.license ? newProps.license.holder_first_name : undefined,
            last_name: newProps.license ? newProps.license.holder_last_name : undefined,
            expire_at: newProps.license ? moment(new Date(newProps.license.expire_at)).format("YYYY-MM-DD") : undefined
        }});
    }

    handleInputChange(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.value;
        this.setState({values: values});
    }

    renderBody() {
        return (
            <div>
                <div className="form-group">
                    <label>N° de licence</label>
                    <input className="form-control" type="text" placeholder="Numéro de la licence" name="license_id" onChange={(e) => {this.handleInputChange(e)}} value={this.state.values.license_id}/>
                </div>
                <div className="form-group">
                    <label>Prénom du porteur</label>
                    <input className="form-control" type="text" placeholder="Prénom" name="first_name" onChange={(e) => {this.handleInputChange(e)}} value={this.state.values.first_name}/>
                </div>
                <div className="form-group">
                    <label>Nom du porteur</label>
                    <input className="form-control" type="text" placeholder="Nom" name="last_name" onChange={(e) => {this.handleInputChange(e)}} value={this.state.values.last_name}/>
                </div>
                <div className="form-group">
                    <label>Date d'expiration</label>
                    <input className="form-control" type="date" placeholder="Nom" name="expire_at" onChange={(e) => {this.handleInputChange(e)}} value={this.state.values.expire_at}/>
                </div>
            </div>
        )
    }

    formIsIncomplete() {
        return this.state.values.license_id === undefined || this.state.values.first_name === undefined || this.state.values.last_name === undefined || this.state.values.expire_at === undefined;
    }

    addLicenseToCustomer() {
        this.setState({loading: true}, () => {
            if (this.props.license) {
                LicenseService.editLicense(this.props.license.id, this.state.values, () => {
                    this.setState({loading: false}, () => this.props.onClose());
                }, () => {
                    this.setState({loading: false}, () => this.props.onClose());
                });
            }
            else {
                LicenseService.createLicense(this.props.member.id, this.state.values, () => {
                    this.setState({loading: false}, () => this.props.onClose());
                }, (error) => {
                    this.setState({loading: false}, () => this.props.onClose());
                });    
            }
        })
    }

    render() {
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> {this.props.license ? "Modifier" : "Ajouter"} une licence </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderBody()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => {this.addLicenseToCustomer()}} disabled={this.formIsIncomplete() || this.state.loading}>
                        {this.props.license ? "Modifier" : "Ajouter"} la licence
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddLicenseModal;