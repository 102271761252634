import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import Select from './select';
import { BoatService } from '../../../services/boat_service';

class   AddBoatModal extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            currentPage: 0,
            pages: [
                {
                    title: "Information du bateau",
                    fields: [
                        [{ key: "name", type: "text", placeholder: "Nom du bateau"}],
                        [{ key: "brand", type: "text", placeholder: "Constructeur"}, { key: "model", type: "text", placeholder: "Modèle"}],
                        [{ key: "length", type: "number", placeholder: "Longueur (m)", step: "0.01"}, { key: "width", type: "number", placeholder: "Largeur (m)", step: "0.01"}]
                    ]
                },
                {
                    title: "Ponton " + (newProps.boat ? "": "(Optionnel)"),
                    fields: [
                        [{ key: "active_year", type: "picker", placeholder: "Saison", onChange: (data) => {this.handleDatePicked("active_year", data)}, options: [{display_name: new Date().getFullYear()}, {display_name: new Date().getFullYear() + 1}, {display_name: new Date().getFullYear() - 1}], required: false }],
                        [{ key: "pontoon", type: "text", placeholder: "Ponton (C,D,E,F)", required: false}, { key: "quatway_number", type: "number", placeholder: "Numéro (1-?)", required: false}]
                    ]
                }
            ],
            values: {},
            loading: false,
            boatId: newProps.boat ? newProps.boat.id : undefined,
            error: undefined
        }, () => {
            if (this.state.boatId !== undefined && newProps.editPontoon === true) {
                this.boatIsCreated(() => {});
            }
            else if (this.state.boatId !== undefined && newProps.editBoat === true) {
                const { pages, values } = this.state;

                pages.pop();
                pages[0].fields.forEach(fieldRow => {
                    fieldRow.forEach(field => {
                        field.required = true;
                        values[field.key] = newProps.boat[field.key];
                    });
                });
                this.setState({currentPage: 0, pages: pages, values: values});
            }
        });
    }

    boatIsCreated(completion) {
        const { pages } = this.state;
        pages.shift();
        pages[0].fields.forEach(fieldRow => {
            fieldRow.forEach(field => {
                field.required = true;
            });
        });
        this.setState({currentPage: 0, pages: pages}, completion);
    }

    nextButtonDisabled() {
        const page = this.state.pages[this.state.currentPage];
        var enabled = true;

        page.fields.forEach(fieldRow => {
            fieldRow.forEach(field => {
                if ((field.required === undefined || field.required) && (this.state.values[field.key] === undefined || this.state.values[field.key] === "")) {
                    enabled = false;
                }    
            });
        });
        return !enabled;
    }

    handleDatePicked(name, value) {
        const { values } = this.state;

        if (value === "-1") {
            values[name] = undefined;
        }
        else {
            values[name] = value;
        }
        this.setState({values: values});
    }

    inputChanged(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.value;
        this.setState({values: values});
    }

    renderForm() {
        const form = this.state.pages[this.state.currentPage];
        const formFields = form.fields;
        var id = 0;
        const formHtml = formFields.map(fieldRow => {
            return (
                <div className="row" key={id++}>
                    {
                    fieldRow.map(field => {
                        if (field.type === "picker") {
                            return (
                                <div key={id++} className={"col form-group " + field.extraClassname}>
                                    <label>{field.placeholder}</label>
                                    <Select options={field.options} onChange={(e) => {field.onChange ? field.onChange(e) : this.inputChanged(e)}}/>
                                </div>
                            );
                        }
                        return (
                            <div key={id++} className={"col form-group " + field.extraClassname}>
                                <input value={this.state.values[field.key] !== undefined ? this.state.values[field.key] : ""} name={field.key} className="form-control" type={field.type} step={field.step} placeholder={field.placeholder} accept={field.accept} onChange={(e) => {field.onChange ? field.onChange(e) : this.inputChanged(e)}}/>
                            </div>
                        );
                    })
                    }
                </div>
            );
        });
        return formHtml;
    }

    renderNextButton() {
        if (this.state.currentPage >= this.state.pages.length - 1) {
            return (
                <Button variant="outline-primary" disabled={this.nextButtonDisabled() || this.state.loading} onClick={() => {this.handleCreate()}}>
                    {this.state.boatId !== undefined ? "Modifier" : "Ajouter"}
                </Button>
            )
        }
        return (
            <Button variant="outline-primary" disabled={this.nextButtonDisabled()} onClick={() => {this.setState({currentPage: this.state.currentPage + 1})}}>
                Suivant
            </Button>
        )
    }

    renderPrevButton() {
        if (this.state.currentPage > 0) {
            return (
                <Button variant="outline-secondary" onClick={() => {this.setState({currentPage: this.state.currentPage - 1})}}>
                    Précédent
                </Button>
            );
        }
        return (
            <Button variant="light" onClick={this.props.onClose}>
                Annuler
            </Button>
        );
    }

    parkBoat() {
        const { active_year, pontoon, quatway_number } = this.state.values;

        if (active_year && pontoon && quatway_number) {
            this.setState({loading: true}, () => {
                BoatService.parkBoat(this.state.boatId, this.state.values, () => {
                    this.props.onClose();
                }, (error) => {
                    this.setState({loading: false, error: error.message});
                });
            });

        }
        else {
            this.setState({error: "Le bateau a bien été créé mais il manque des données pour la place de ponton. Pour remettre cette action à plus tard, appuyez sur Annuler"});
        }
    }

    editBoat() {
        this.setState({loading: true}, () => {
            BoatService.updateBoat(this.state.boatId, this.state.values, () => {
                this.setState({loading: false}, () => {this.props.onClose()});
            }, (error) => {
                this.setState({loading: false, error: error.message});
            })
        });
    }

    handleCreate() {
        if (this.props.editPontoon === true) {
            this.parkBoat();
        }
        else if (this.props.editBoat === true) {
            this.editBoat();
        }
        else {
            this.setState({loading: true}, () => {
                BoatService.createBoat(this.props.member.id, this.state.values, (boatId) => {
                    this.setState({loading: false, boatId: boatId}, () => {
                        const { active_year, pontoon, quatway_number } = this.state.values;

                        if (active_year || pontoon || quatway_number) {
                            this.boatIsCreated(() => {
                                this.parkBoat();
                            });
                        }
                        else {
                            this.props.onClose();
                        }
                    });
                }, (err) => {
                    this.setState({loading: false});
                });    
            });    
        }
    }

    
    render() {
        if (this.state["currentPage"] === undefined) {
            return (<div></div>)
        }
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> {this.state.pages[this.state.currentPage].title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderForm()}
                    <p className="text-danger">{this.state.error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <p className="step-count"> Etape {this.state.currentPage + 1} / {this.state.pages.length} </p>
                    {this.renderPrevButton()}
                    {this.renderNextButton()}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddBoatModal;