import React, { Component } from 'react';
import queryString from 'query-string';
import { AdminService } from '../../services/admin_service';

class   AccessPage extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            password: undefined,
            error: undefined
        }
    }

    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        
        if (parsed.uuid) {
            AdminService.getPasswordWithAccess(parsed.uuid, (infos) => {
                this.setState({password: infos.password, error: undefined});
            }, (error) => {
                this.setState({password: undefined, error: "Non autorisé, Vous ne devriez pas être là."});
            });
        }   
        else {
            this.setState({password: undefined, error: "Non autorisé, Vous ne devriez pas être là."});
        }     
    }

    renderBody() {
        if (this.state.password !== undefined && this.state.error === undefined) {
            return (
                <h3 style={{textAlign: 'center'}} className="login-title">Votre mot de passe est le suivant: <p className="font-weight-bold">{this.state.password}</p></h3>
            );
        }
        else {
            return (
                <h3 style={{textAlign: 'center'}} className="text-danger"> {this.state.error} </h3>
            );
        }
    }

    render() {
        return (
			<div className="login-page">
				<div className="row h-100">
					<div className="col-md-4 col-12 offset-md-8 login-form-container">
						<div className="login-form">
							<div>
								<h1 className="login-title"> CNG - Club Manager </h1>
                                {this.renderBody()}
                                <button className="form-control btn btn-primary" onClick={() => {this.props.history.push('/login')}}>Page de connexion</button>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

export default AccessPage;