import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import "./button.css";

export class	Button extends Component {

	render() {
		return (
			<Link to={this.props.buttonHref} className={"btn btn-outline-primary btn-labeled " + (this.props.active ? "active" : "")}>
                <span className="btn-label">
					<i className={this.props.buttonIcon}></i>
				</span>
				<span className="button-title"> {this.props.buttonTitle} </span>
			</Link>
		)
	}
}

export class	HomeButton extends Component {
	render() {
		return (
			<Button buttonHref="/" buttonTitle="Accueil" buttonIcon="fa fa-home" {...this.props}/>
		)
	}
}

export class	MembersButton extends Component {
	render() {
		return (
			<Button buttonHref="/members" buttonTitle="Adhérents" buttonIcon="fas fa-users" {...this.props}/>
		)
	}
}

export class	HarborButton extends Component {
	render() {
		return (
			<Button buttonHref="/harbor" buttonTitle="Port" buttonIcon="fas fa-anchor" {...this.props}/>
		)
	}
}

export class	ConfigButton extends Component {
	render() {
		return (
			<Button buttonHref="/administration" buttonTitle="Administration" buttonIcon="fa fa-cogs" {...this.props}/>
		)
	}
}

export const	StatsButton = (props) => {
	return (
		<Button buttonHref="/stats" buttonTitle="Statistiques" buttonIcon="fas fa-chart-pie" {...props} />
	)
}

export const	WebsiteButton = (props) => {
	return (
		<Button buttonHref="/website" buttonTitle="Website" buttonIcon="fas fa-globe" {...props} />
	)
}

export class	SignoutButton extends Component {
	render() {
		return (
			<button className={"btn btn-outline-primary btn-yeswevent btn-labeled"} {...this.props}>
				<span className="btn-label">
					<i className="fas fa-sign-out-alt"></i>
				</span>
				<span className="button-title"> Déconnexion </span>
			</button>
		)
	}
}
