import { ApiService } from "./api_service";

export class AdminService extends ApiService {
    /**
     * Login API call
     */
    static login(email, password, callback, callback_error) {
        this.executePut("auth", {email: email, password: password}, (data) => {
            const token = data['token'];
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(data));
            this.initialization();
            callback(true, data);
        }, callback_error);
    }

    static disconnect() {
		localStorage.removeItem("user");
		localStorage.removeItem("token");
    }
    
    static  getPasswordWithAccess(accessUuid, callback, callback_error) {
        this.executeGet(`access/${accessUuid}`, callback, callback_error);
    }

    static  getAdministrators(callback, callback_error) {
        this.executeGet(`users`, callback, callback_error);
    }

    static  createAdministrator(adminInfos, callback, callback_error) {
        this.executePut("users", adminInfos, callback, callback_error);
    }

    static  deleteAdministrator(adminId, callback, callback_error) {
        this.executeDelete(`users/${adminId}`, {}, callback, callback_error);
    }

}