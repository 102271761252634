import { ApiService } from "./api_service";

export class BadgeService extends ApiService {

    static getBadges(memberId, callback, callback_error) {
        this.executeGet(`badges/${memberId}`, callback, callback_error);
    }

    static  createBadge(customerId, badgeInfos, callback, callback_error) {
        this.executePut(`badges/${customerId}`, badgeInfos, callback, callback_error);
    }

    static  editBadge(customerId, badgeId, badgeInfos, callback, callback_error) {
        this.executePost(`badges/${customerId}/${badgeId}`, badgeInfos, callback, callback_error);
    }

    static  deleteBadge(customerId, badgeId, callback, callback_error) {
        this.executeDelete(`badges/${customerId}/${badgeId}`, {}, callback, callback_error);
    }


}