import React from 'react';
import { Link } from 'react-router-dom';

const Configuration = (props) => {
    return (
        <div className="col-auto">
            <Link className={"btn configuration-button btn-outline-" + props.variant} to={props.to}> <i class={props.icon}></i> <span className="name">{props.name}</span> </Link>
        </div>
    )
}

export const ConfigurationPagesList = (props) => {
    return (
        <div>
            <h1>Administration du site</h1>
            
            <div className="row">
                <Configuration name={"Actualités"} to={"/website/news"} icon={"fas fa-newspaper"} variant="dark"/>
                <Configuration name={"Sections"} to={"/website/sections"} icon={"fas fa-bars"} variant="dark"/>
                <Configuration name={"Documents"} to={"/website/documents"} icon={"far fa-folder"} variant="dark"/>
            </div>        
        </div>
    )
}