import axios from 'axios';

/**
 * Base class for services
 * Handle all request types
 * Each function check for return status
 */
export class ApiService {
    // URL of the API
    static apiUrl = 'https://api.cng-der.com';

    /**
     * Initialize axios with the Authorization header
     */
    static initialization() {
        if (this.isConnected())
            axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem("token")}`};
	}

    static isConnected() {
        return localStorage.getItem("token") != null;
    }

	static handleError(error, callback_error) {
		if (error.message === "jwt expired") {
			this.disconnect();
		}
		callback_error(error);
	}

	static executePost(resource, body, callback, callback_error) {
		axios.post(`${ApiService.apiUrl}/${resource}`, body)
			.then(response => {
				if (response.data["success"] === true) {
					callback(response.data.data);
				}
				else {
					this.handleError(new Error(response.data.error), callback_error);
				}
			})
			.catch(err => {
				this.handleError(err, callback_error);
			});
	}

	static executeGet(resource, callback, callback_error) {
		axios.get(`${ApiService.apiUrl}/${resource}`)
			.then(response => {
				if (response.data["success"] === true) {
					callback(response.data.data);
				}
				else {
					this.handleError(new Error(response.data.error), callback_error);
				}
			})
			.catch(err => {
				this.handleError(err, callback_error);
			});
	}

	static executePut(resource, body, callback, callback_error) {
		axios.put(`${ApiService.apiUrl}/${resource}`, body)
			.then(response => {
				if (response.data["success"] === true) {
					callback(response.data.data);
				}
				else {
					this.handleError(new Error(response.data.error), callback_error);
				}
			})
			.catch(err => {
				this.handleError(err, callback_error);
			});
	}

	static executeDelete(resource, body, callback, callback_error) {
		axios.delete(`${ApiService.apiUrl}/${resource}`, body)
			.then(response => {
				if (response.data["success"] === true) {
					callback(response.data.data);
				}
				else {
					this.handleError(new Error(response.data.error), callback_error);
				}
			})
			.catch(err => {
				this.handleError(err, callback_error);
			});
	}

    static  getUserInfos() {
        if (this.isConnected()) {
            return JSON.parse(localStorage.getItem("user"));
        }
        return {}
    }
}
