import React, { Component } from 'react';

class	HomePage extends Component {
	render() {
		return (
			<div>
				<h1> Accueil </h1>
				<h4> Bienvenue sur le site d'administration du CN Giffaumont</h4>
				<img style={{width: "100%"}} alt="CNGiffaumont" src="https://www.cng-der.com/background/3.jpg"/>
			</div>
		)
	}
}

export default HomePage;