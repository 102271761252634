import React, { Component } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { NewsService } from '../../../../services/news_service';
import Moment from 'react-moment';
import 'moment/locale/fr';

import "./news_list.css";
import { ApiService } from '../../../../services/api_service';
import { AllHtmlEntities } from 'html-entities';


const PublishState = (props) => {
    if (props.state === "published") {
        return (
            <div className="publish-state published">
                <i className="fas fa-check"></i>
            </div>
        )
    }
    return (
        <div className="publish-state unpublished">
            <i className="far fa-eye-slash"></i>
        </div>
    )
}

const VisibilityButton = (props) => {
    if (props.state === "published") {
        return (
            <Dropdown.Item onClick={() => props.onUnpublishNews(props.id)}>Rendre invisible</Dropdown.Item>
        )
    }
    else {
        return (
            <Dropdown.Item onClick={() => props.onPublishNews(props.id)}>Publier l'article</Dropdown.Item>
        )
    }
}

const DropdownActions = (props) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="link" id="news-dropdown">
                Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <VisibilityButton {...props} />
                <Dropdown.Item onClick={() => props.onEditNews(props.id)}>Modifier</Dropdown.Item>
                <Dropdown.Item className="text-danger" onClick={() => props.onDeleteNews(props.id)}>Supprimer</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

const NewsThumbnail = (props) => {

	return (
		<div className="col-lg-4 col-sm-6 news-thumbnail">
			<div class="card">
				<img class="card-img-top" src={`${ApiService.apiUrl}/${props.thumbnail_file_name}`} alt="News thumbnail" />
				<div class="card-body">
                    <div className="row headline-container">
                        <div className="col news-title-thumb-container">
                            <h5 class="card-title news-title-thumb"><PublishState {...props} /> {props.title}</h5>
                        </div>
                        <div className="col-auto">
                            <DropdownActions {...props}/>
                        </div>
                    </div>
					<p class="card-text news-content-preview" >{`${AllHtmlEntities.decode(props.content.replace(/<\/?[^>]+(>|$)/g, ""))}`}</p>
                    <div className="row">
                        <div className="col">
                            <div className="writer-container">
                                <img src={props.writer_pic} className="writer-pic" alt="writer-pic"/><span className="writer-name">{props.writer_fname} {props.writer_lname}</span>
                            </div>        
                        </div>
                        <div className="col">
                            <p className="publish-time"> Publié <Moment locale="fr" interval={10} fromNow>{props.created_at}</Moment> </p>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	)
}


export class NewsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            news: [],
            error: undefined
        }
    }

    componentDidMount() {
        this.getNews();
    }

    getNews() {
        this.setState({isLoading: true}, () => {
            NewsService.getNews((news) => {
                this.setState({news: news, error: undefined, isLoading: false});
            }, (error) => {
                this.setState({news: [], error: error, isLoading: false});
            });    
        })
    }

    publishNews(newsId) {
        this.setState({isLoading: true}, () => {
            NewsService.publishNews(newsId, () => {
                this.getNews();
            }, (error) => this.setState({error: error, isLoading: false}));    
        });
    }
    
    unpublishNews(newsId) {
        this.setState({isLoading: true}, () => {
            NewsService.unpublishNews(newsId, () => {
                this.getNews();
            }, (error) => this.setState({error: error, isLoading: false}));    
        });
    }

    deleteNews(newsId) {
        this.setState({isLoading: true}, () => {
            NewsService.deleteNews(newsId, () => this.getNews(), (error) => this.setState({error: error, isLoading: false}));
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <h1> Administration des articles <Button variant="primary" href="/website/news/creator">Ajouter un article</Button></h1>
                    <div className="loading-container">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            );
        }
        const news = this.state.news.map(news => {
            return <NewsThumbnail {...news}
                    onPublishNews={(newsId) => this.publishNews(newsId)}
                    onUnpublishNews={(newsId) => this.unpublishNews(newsId)}
                    onDeleteNews={(newsId) => this.deleteNews(newsId)}
                    onEditNews={(newsId) => this.props.history.push(`/website/news/${newsId}/edit`)}
                    />
        })
        return (
            <div>
                <h1> Administration des articles <Button variant="primary" href="/website/news/creator">Ajouter un article</Button></h1>
                <div className="row">
                    {news}
                </div>
            </div>
        )
    }
}