import React, { Component } from 'react';

import ConfirmationModal from '../../common/confirmationModal';
import Select from '../../member_page/components/select';

import './services_list.css';
import AddServiceModal from './add_service_modal';
import { ServiceManager } from '../../../services/service_manager';

export default class    ServiceList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDeleteService: false,
            selectedService: undefined,
            showEditService: false,
            loading: false,
            services: [],
            availableYears: [],
            selectedYear: "-1",
            error: undefined,
            years: [],
            showAddServiceModal: false
        }
    }

    componentDidMount() {
        this.getAvailableYears();
    }

    getAvailableYears() {
        this.setState({loading: true}, () => {
            ServiceManager.getYears((years) => {
                const foundNextYear = years.find(year => {return year.active_year === new Date().getFullYear() + 1});
                if (!foundNextYear) {
                    years.push({active_year: new Date().getFullYear() + 1});
                }
                const found = years.find(year => {return year.active_year === new Date().getFullYear()});
                if (!found) {
                    years.unshift({active_year: new Date().getFullYear()});
                }
                if (years.length > 0) {
                    this.setState({error: undefined, loading: false, years: years, selectedYear: years[0].active_year}, () => {this.retrieveServiceList()});
                }
                else {
                    this.setState({error: "Aucune service est disponible.", loading: false, years: years, selectedYear: "-1"}, () => {this.retrieveServiceList()});
                }
            }, (error) => {
                this.setState({error: error.message, loading: false});
            });
        });
    }

    retrieveServiceList() {
        this.setState({loading: true}, () => {
            ServiceManager.getServicesList(this.state.selectedYear, (services) => {
                this.setState({services: services, selectedService: undefined, loading: false});
            }, (error) => {
                this.setState({loading: false, error: error.message});
            });    
        });
    }

    editService() {
        this.setState({loading: true}, () => {
            ServiceManager.editService(this.state.selectedService)
        });
    }

    renderYearSelector() {
        const options = this.state.years.map(year => {
            return ({value: year.active_year, display_name: year.active_year});
        });
        return (
            <div>
                <div className="form-group select-group">
                    <Select selected={this.state.selectedYear} default={false} options={options} onChange={(year) => {this.setState({selectedYear: year}, () => {this.retrieveServiceList()})}}/>
                </div>
            </div>
        );    
    }

    renderTableHead() {
        return (
            <tr>
                <th className="thead-title" scope="col">ID</th>
                <th className="thead-title" scope="col">Nom</th>
                <th className="thead-title" scope="col">Prix</th>
                <th className="thead-title" scope="col">Actions</th>
            </tr>
        );
    }

    deleteService() {
        const { selectedService } = this.state;

        if (selectedService !== undefined) {
            this.setState({loading: true, showDeleteService: false}, () => {
                ServiceManager.deleteService(this.state.selectedService.id, () => {
                    this.retrieveServiceList();
                }, (error) => {});
            });
        }
    }

    renderActions(data) {
        const buttons = []

        buttons.push(<button className="btn btn-outline-primary" title="Modifier le service" onClick={() => {this.setState({showEditService: true, selectedService: data})}}><i className="fas fa-cog"></i></button>);
        buttons.push(<button className="btn btn-outline-danger" title="Supprimer le service" onClick={() => {this.setState({showDeleteService: true, selectedService: data})}}><i className="far fa-times-circle"></i></button>)
        return (
            <div className="btn-group">
                {buttons}
            </div>
        );
    }
    
    renderTableBody() {
        return (
            this.state.services.map(service => {
                return (
                    <tr key={service.id}>
                        <th scope="row">{service.id}</th>
                        <td>{service.name}</td>
                        <td>{service.price}€</td>
                        <td style={{textAlign: 'center'}}>{this.renderActions(service)}</td>
                    </tr>
                )
            })
        )
    }

    renderHeader() {
        return (
            <div>
                {this.renderYearSelector()}
                    <button className="btn btn-outline-primary" onClick={() => {this.setState({showAddServiceModal: true})}}>
                    <i className="fas fa-plus"></i> Ajouter un Service
                </button>
                <AddServiceModal selectedYear={this.state.selectedYear} edit={true} active={this.state.showEditService} service={this.state.selectedService} onClose={() => {this.setState({showEditService: false}, () => {this.retrieveServiceList()})}} />
                <AddServiceModal selectedYear={this.state.selectedYear} active={this.state.showAddServiceModal} onClose={() => {this.setState({showAddServiceModal: false}, () => {this.retrieveServiceList()})}}/>
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if (!this.state.services || this.state.services.length === 0) {
            return (
                <div className="table-container">
                    {this.renderHeader()}
                    <p> Aucun service n'est disponible pour l'année {this.state.selectedYear} </p>
                </div>
            )
        }
        return (
            <div>
                {this.renderHeader()}
                <table className="table table-container">
                    <thead className="thead-light">
                        {this.renderTableHead()}
                    </thead>
                    <tbody>
                        {this.renderTableBody()}
                    </tbody>
                    <ConfirmationModal
                        active={this.state.showDeleteService}
                        variant={"danger"}
                        title="Êtes-vous sûr de vouloir supprimer ce service ?"
                        content={"Ce service ne sera plus disponible à l'affectation et toutes les données reliées seront perdues !"}
                        onClose={() => {this.setState({showDeleteService: false, selectedService: undefined})}}
                        execute={() => {this.deleteService()}}
                    />
                </table>
            </div>
        )
    }
}