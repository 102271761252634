import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { SectionService } from '../../../services/section_service';
import { HtmlEditor } from '../../common/html_editor';

const SectionSelector = (props) => {
    const options = props.sections.map(section => {
        if (section.id === props.selected.id) {
            return <option name={section.name} value={section.id} selected>{section.name}</option>
        }
        return <option name={section.name} value={section.id}>{section.name}</option>
    })
    return (
        <div>
            <Form.Group>
                <Form.Label>Choix de la section à modifier</Form.Label>
                <Form.Control as="select" onChange={e => props.setActiveSection(parseInt(e.target.value))}>
                    {options}
                </Form.Control>
            </Form.Group>
        </div>
    )
}

export class WebsiteSectionsEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sections: [],
            activeSection: undefined,
            isLoading: false,
            newSectionContent: undefined,
            isUpdating: false
        } 
    }

    componentDidMount() {
        this.getSections()
    }

    getSections() {
        this.setState({isLoading: true}, () => {  
            SectionService.getSections(sections => {
                this.setState({sections: sections, activeSection: sections[0], isLoading: false, newSectionContent: sections[0].content})
            }, error => this.setState({isLoading: false}))    
        })
    }

    updateSection() {
        const { activeSection, newSectionContent } = this.state;

        if (activeSection === undefined) {
            return;
        }
        this.setState({isUpdating: true}, () => {
            SectionService.updateSection(activeSection.id, {name: activeSection.name, path: activeSection.path, content: newSectionContent}, () => {
                this.setState({isUpdating: false}, () => this.getSections())
            }, () => this.setState({isUpdating: false}));
        });
    }

    findActiveSection(id) {
        return this.state.sections.filter(section => section.id === id)[0];
    }

    renderEditor() {
        const { activeSection } = this.state;
        if (activeSection === undefined) {
            return (<div></div>)
        }
        return (
            <div>
                <h2>{activeSection.name}</h2>
                <HtmlEditor content={this.state.newSectionContent} onEditorChange={(content) => this.setState({newSectionContent: content})}/>
                <Form.Group>
                    <Button variant="primary" style={{marginTop: "18px"}} disabled={this.state.isUpdating} onClick={() => this.updateSection()}>{this.state.isUpdating ? <i className="fa fa-circle-o-notch fa-spin"></i> : <></>} Mettre à jour</Button>
                </Form.Group>
            </div>

        )
    } 

    render() {
        return (
            <div>
                <h1> Modification des sections </h1>
                <SectionSelector sections={this.state.sections} selected={this.state.activeSection} setActiveSection={(sectionId) => this.setState({activeSection: this.findActiveSection(sectionId), newSectionContent: this.findActiveSection(sectionId).content})}/>
                {this.renderEditor()}
            </div>
        )
    } 
}
