import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import LoginPage from '../components/login_page/login_page';
import history from '../components/history/history';
import { ApiService } from '../services/api_service';
import { AdminRouter } from './admin';
import AccessPage from '../components/access_page/access_page';

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={(props) => (
		ApiService.isConnected() === true
			? <Component {...props} />
			: <Redirect to={{
				pathname: '/login',
				state: { from: props.location }
			}} />
	)} />
);

const BaseRouter = () => {
	ApiService.initialization();

	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/login" component={LoginPage} />
				<Route path="/access" component={AccessPage}/>
				<PrivateRoute path="/" component={AdminRouter} />
			</Switch>
		</Router>
	);
}

export default BaseRouter;
