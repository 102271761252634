import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import history from '../../history/history';
import { NewsCreator } from './news_creator';
import { NewsEditor } from './news_creator/edit';
import { NewsList } from './news_list';

export const WebsiteNewsPagesRouter = () => {
    return (
        <div>
            <Router history={history}>
                <Switch>
                    <Route exact path="/website/news" component={NewsList} />
                    <Route exact path="/website/news/creator" component={NewsCreator} />
                    <Route exact path="/website/news/:newsId/edit" component={NewsEditor} />
                </Switch>
            </Router>
        </div>
    )
}