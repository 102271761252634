import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './member_page.css';
import { ErrorNotification } from '../containers/notifications';
import { Container } from '../containers/container';
import { BillsTable, PaymentsTable, BoatsTable, BadgesTable, LicenseTable, FilesTable } from './components/data_tables';
import AddBillModal from './components/add_bill_modal';
import AddBoatModal from './components/add_boat_modal';
import AddBadgeModal from './components/add_badge_modal';
import AddMemberModal from '../members_page/components/add_member';
import AddLicenseModal from './components/add_license_modal';
import ConfirmationModal from '../common/confirmationModal';
import AddFileModal from './components/add_file_modal';
import { FileService } from '../../services/file_service';
import { MemberService } from '../../services/member_service';
import { BillService } from '../../services/bill_service';
import { PaymentService } from '../../services/payment_service';
import { BoatService } from '../../services/boat_service';
import { BadgeService } from '../../services/badge_service';
import { LicenseService } from '../../services/license_service';

class   ProfileContainer extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            showEditMemberModal: false,
            showDeleteMemberModal: false
        }
    }

    deleteMember() {
        MemberService.deleteMember(this.props.member.id, () => {
            this.props.history.push('/members');
        }, () => {

        });
    }

    renderMemberState(state) {
        if (state === "member") {
            return (<div className="badge badge-success badge-outlined"> Membre </div>);
        }
        else if (state === "associate_member") {
            return (<div className="badge badge-success badge-outlined"> Membre associé</div>);
        }
        else if (state === "temporary") {
            return (<div className="badge badge-warning badge-outlined"> Passager </div>);
        }
        else if (state === "old_member") {
            return (<div className="badge badge-danger badge-outlined"> Ancien membre </div>);
        }
        else if (state === "admin_member") {
            return (<div className="badge badge-secondary badge-outlined"> Membre au bureau </div>);
        }
        else {
            return (<div className="badge badge-primary badge-outlined"> Nouveau </div>);
        }
    }

    render() {
        return (
            <Container className="profile-container">
                <div className="profile-toolbar">
                    <button className="btn btn-outline-primary" onClick={() => {this.setState({showEditMemberModal: true})}}><i className="fas fa-edit"></i> Modifier</button>
                    <button className="btn btn-outline-danger" onClick={() => {this.setState({showDeleteMemberModal: true})}}><i className="far fa-times-circle"></i> Supprimer</button>
                </div>
                <div className="profile-infos">
                    <div className="profile-headline profile-section">
                        <img className="member-profile-picture-large" src={FileService.renderMemberProfilePicture(this.props.member)} alt="member profile"/>
                        <h3> {this.props.member.first_name} {this.props.member.last_name} </h3>
                        <div className="state-container">{this.renderMemberState(this.props.member.state)}</div>
                    </div>
                    <div className="profile-main-informations">
                        <div className="profile-data">
                            <p> <span className="profile-data-key">Téléphone: </span> {this.props.member.mobile_phone} </p>
                        </div>
                        <div className="profile-data profile-section">
                            <p> <span className="profile-data-key">E-mail: </span> {this.props.member.email} </p>
                        </div>
                        <div className="profile-data">
                            <p> <span className="profile-data-key">Date de naissance: </span> {new Date(this.props.member.birth_date).toLocaleDateString("fr")} </p>
                        </div>
                        <div className="profile-data">
                            <p> <span className="profile-data-key">Profession: </span> {this.props.member.job ? this.props.member.job : "Inconnu"} </p>
                        </div>
                        <div className="profile-data">
                            <p> <span className="profile-data-key">Ville: </span> {this.props.member.address_city} </p>
                        </div>
                        <div className="profile-data">
                            <p> <span className="profile-data-key">Adresse: </span> {this.props.member.address_line} </p>
                        </div>
                        <div className="profile-data profile-section">
                            <p> <span className="profile-data-key">Code postal: </span> {this.props.member.address_postal_code} </p>
                        </div>
                        <div className="profile-data">
                            <p> <span className="profile-data-key">Régatier: </span> {this.props.member.is_sailor ? "Oui" : "Non"} </p>
                        </div>
                    </div>
                </div>
                <AddMemberModal active={this.state.showEditMemberModal} member={this.props.member} edit={true} onClose={() => {this.setState({showEditMemberModal: false}, this.props.getMember())}} />
                <ConfirmationModal
                    active={this.state.showDeleteMemberModal}
                    variant={"danger"}
                    title="Êtes-vous sûr de vouloir supprimer cet adhérent ?"
                    content={"Cette action est irréversible et causera la suppression des bateaux et des services reliés à cet adhérent !"}
                    onClose={() => {this.setState({showDeleteMemberModal: false})}}
                    execute={() => {this.deleteMember()}}
                />
            </Container>
        );
    }
}

const   ProfileContainerWithRouter = withRouter(ProfileContainer);

class   MemberPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            member: undefined,
            loading: false,
            notification: <></>,
            billsLoading: false,
            bills: [],
            paymentsLoading: false,
            payments: [],
            showBillModal: false,
            showAddBoatModal: false,
            boats: [],
            boatsLoading: false,
            badgesLoading: false,
            badges: [],
            showAddBadgeModal: false,
            licensesLoading: false,
            licenses: [],
            showAddLicenseModal: false,
            showAddFileModal: false,
            filesLoading: false,
            files: []
        }
    }

    componentDidMount() {
        this.getMember();
        this.getBills();
        this.getPayments();
        this.getBoats();
        this.getBadges();
        this.getLicenses();
        this.getFiles();
    }

    getMember() {
        this.setState({ loading: true }, () => {
            MemberService.getMember(this.props.match.params.memberId, (member) => {
                this.setState({ loading: false, member: member})
            }, (error) => {
                if (typeof error === "object") {
                    this.setState({ loading: true, notification: <ErrorNotification errorMessage={"Connexion au serveur impossible"} />})
                }
                else {
                    this.setState({ loading: true, notification: <ErrorNotification errorMessage={error} />})
                }
            });
        });
    }

    getBills() {
        this.setState({ billsLoading: true }, () => {
            BillService.getBills(this.props.match.params.memberId, (bills) => {
                this.setState({ billsLoading: false, bills: bills });
            }, () => {
                this.setState({ billsLoading: false });
            });
        });
    }

    getPayments() {
        this.setState({paymentsLoading: true}, () => {
            PaymentService.getPayments(this.props.match.params.memberId, (payments) => {
                this.setState({ paymentsLoading: false, payments: payments });
            }, () => {
                this.setState( {paymentsLoading: false });
            });
        });
    }

    getBoats() {
        this.setState({boatsLoading: true}, () => {
            BoatService.getBoats(this.props.match.params.memberId, (boats) => {
                this.setState({boats: boats, boatsLoading: false});
            }, () => {
                this.setState({boats: [], boatsLoading: false});
            });
        });
    }

    getBadges() {
        this.setState({badgesLoading: true}, () => {
            BadgeService.getBadges(this.props.match.params.memberId, (badges) => {
                this.setState({badges: badges, badgesLoading: false});
            }, () => {
                this.setState({badges: [], badgesLoading: false});
            });
        });
    }

    getLicenses() {
        this.setState({licensesLoading: true}, () => {
            LicenseService.getLicenses(this.props.match.params.memberId, (licenses) => {
                this.setState({licenses: licenses, licensesLoading: false});
            }, () => {
                this.setState({licenses: [], licensesLoading: false})
            });
        });
    }

    getFiles() {
        this.setState({filesLoading: true}, () => {
            FileService.getFiles(this.props.match.params.memberId, (files) => {
                this.setState({files: files, filesLoading: false});
            }, () => {
                this.setState({files: [], filesLoading: false});
            });
        });
    }

    pushNotification(notification) {
        this.setState({ notification: notification });
    }

    render() {
        if (this.state.loading || this.state.member === undefined) {
            return (
                <div className="loading-container">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    {this.state.notification}
                </div>
            );
        }
        return (
            <div className="row member-profil-full-container">
                <div className="col-lg-9 col-12">
                    <div className="row">
                        <div className="col-12">
                            <Container tintColor="#e74c3c">
                                <div className="row">
                                    <h3 className="col"> Factures </h3>
                                    <div className="col btn-toolbar-container">
                                        <button className="btn btn-outline-primary" onClick={() => {this.setState({showBillModal: true})}}>
                                            <i className="fas fa-plus"></i> Ajouter un service
                                        </button>
                                    </div>
                                </div>
                                <BillsTable loading={this.state.billsLoading} member={this.state.member} data={this.state.bills} tableIsEmpty={"Aucune facture n'a été trouvée"} updateDatas={() => {this.getBills(); this.getPayments()}}/>
                            </Container>    
                        </div>
                        <div className="col-12">
                            <Container tintColor="#2ecc71">
                                <div className="row">
                                    <h3 className="col"> Paiements </h3>
                                </div>
                                <PaymentsTable loading={this.state.paymentsLoading} data={this.state.payments} tableIsEmpty={"Aucun paiement n'a été trouvé"}/>
                            </Container>
                        </div>
                        <div className="col-12">
                            <Container tintColor="#8e44ad">
                                <div className="row">
                                    <h3 className="col"> Bateaux </h3>
                                    <div className="col btn-toolbar-container">
                                        <button className="btn btn-outline-primary" onClick={() => {this.setState({showAddBoatModal: true})}}>
                                            <i className="fas fa-plus"></i> Ajouter un bateau
                                        </button>
                                    </div>
                                </div>
                                <BoatsTable member={this.state.member} loading={this.state.paymentsLoading} tableIsEmpty={"Aucun bateau n'a été trouvé"} data={this.state.boats} updateDatas={() => {this.getBoats()}}/>
                            </Container>
                        </div>
                        <div className="col-12">
                            <Container tintColor="#e67e22">
                                <div className="row">
                                    <h3 className="col"> Licences </h3>
                                    <div className="col btn-toolbar-container">
                                        <button className="btn btn-outline-primary" onClick={() => {this.setState({showAddLicenseModal: true})}}>
                                            <i className="fas fa-plus"></i> Ajouter une licence
                                        </button>
                                    </div>
                                </div>
                                <LicenseTable member={this.state.member} loading={this.state.licenseLoading} data={this.state.licenses} tableIsEmpty={"Aucune licence n'a été trouvée"} updateDatas={() => {this.getLicenses()}}/>
                            </Container>
                        </div>
                        <div className="col-12">
                            <Container tintColor="#16a085">
                                <div className="row">
                                    <h3 className="col"> Badges </h3>
                                    <div className="col btn-toolbar-container">
                                        <button className="btn btn-outline-primary" onClick={() => {this.setState({showAddBadgeModal: true})}}>
                                            <i className="fas fa-plus"></i> Ajouter un badge
                                        </button>
                                    </div>
                                </div>
                                <BadgesTable member={this.state.member} loading={this.state.badgesLoading} data={this.state.badges} tableIsEmpty={"Aucun badge n'a été trouvé"} updateDatas={() => {this.getBadges()}}/>
                            </Container>
                        </div>
                        <div className="col-12">
                            <Container tintColor="#7f8c8d">
                                <div className="row">
                                    <h3 className="col"> Documents </h3>
                                    <div className="col btn-toolbar-container">
                                        <button className="btn btn-outline-primary" onClick={() => {this.setState({showAddFileModal: true})}}>
                                            <i className="fas fa-plus"></i> Ajouter un document
                                        </button>
                                    </div>
                                </div>
                                <FilesTable member={this.state.member} loading={this.state.filesLoading} data={this.state.files} tableIsEmpty={"Aucun document n'a été trouvé"} updateDatas={() => {this.getFiles()}}/>
                            </Container>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-12 profile-container ">
                    <ProfileContainerWithRouter member={this.state.member} getMember={() => {this.getMember()}}/>
                </div>
                {this.state.notification}
                <AddBillModal member={this.state.member} active={this.state.showBillModal} onClose={() => {this.setState({showBillModal: false}, () => {this.getBills()})}}/>
                <AddBoatModal member={this.state.member} active={this.state.showAddBoatModal} onClose={() => {this.setState({showAddBoatModal: false}, () => {this.getBoats()})}} />
                <AddBadgeModal member={this.state.member} active={this.state.showAddBadgeModal} onClose={() => {this.setState({showAddBadgeModal: false}, () => {this.getBadges()})}} />
                <AddLicenseModal member={this.state.member} active={this.state.showAddLicenseModal} onClose={() => {this.setState({showAddLicenseModal: false}, () => {this.getLicenses()})}} />
                <AddFileModal member={this.state.member} active={this.state.showAddFileModal} onClose={() => {this.setState({showAddFileModal: false}, () => {this.getFiles()})}} />
            </div>
        )
    }
}

export default MemberPage;