import axios from "axios";
import { ApiService } from "./api_service";


export class ImagesService extends ApiService {

    static uploadPicture(file, callback, callback_error) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append("news_picture", file);
        axios.put(this.apiUrl + `/images`, formData, config)
            .then((response) => {
				if (response.data["success"] === true) {
					callback(response.data.data);
				}
				else {
					this.handleError(new Error(response.data.error.message), callback_error);
				}
            })
    }

}