import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import history from '../history/history';
import { ConfigurationPagesList } from './configuration_pages_list';
import { WebsiteDocumentsPagesRouter } from './documents_pages';
import { WebsiteNewsPagesRouter } from './news_pages';
import { WebsiteSectionsEditor } from './sections_pages';

import "./website_pages.css";

export const WebsitePagesRouter = () => {
    return (
        <div>
            <Router history={history}>
                <Switch>
                    <Route exact path="/website" component={ConfigurationPagesList}/>
                    <Route path="/website/news" component={WebsiteNewsPagesRouter}/>
                    <Route path="/website/sections" component={WebsiteSectionsEditor} />
                    <Route path="/website/documents" component={WebsiteDocumentsPagesRouter} />
                </Switch>
            </Router>
        </div>
    )
}