import { ApiService } from "./api_service";

export class BillService extends ApiService {
    static  getBills(memberId, callback, callback_error) {
        this.executeGet(`bills/${memberId}`, callback, callback_error);
    }

    static  pay(billId, amount, type, additional_data, sendMail, callback, callback_error) {
        this.executePost(`bills/${billId}/pay`, {amount: amount, type: type, additional_data: additional_data, send_mail: sendMail}, callback, callback_error);
    }

    static  payAll(customerId, type, additional_data, sendMail, callback, callback_error) {
        this.executePost(`bills/pay_all`, {customer_id: customerId, type: type, additional_data: additional_data, send_mail: sendMail}, callback, callback_error);
    }

    static  deleteBill(billId, callback, callback_error) {
        this.executeDelete(`bills/${billId}/delete`, {}, callback, callback_error);
    }

}