import React, { Component } from 'react';
import ConfirmationModal from '../../common/confirmationModal';
import AddAdministratorModal from './add_administrator_modal';
import { AdminService } from '../../../services/admin_service';

class   AdministratorsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showAddAdminModal: false,
            showDeleteAdmin: false,
            selectedAdmin: undefined,
            loading: false,
            administrators: [],
            error: undefined,
        }
    }

    componentDidMount() {
        this.getAdministrators()
    }

    getAdministrators() {
        this.setState({loading: true}, () => {
            AdminService.getAdministrators((administrators) => {
                this.setState({error: undefined, administrators: administrators, loading: false});
            }, (error) => {
               this.setState({error: error.message, loading: false, administrators: []});
            });
        });
    }

    deleteAdministrator() {
        this.setState({loading: true}, () => {
            AdminService.deleteAdministrator(this.state.selectedAdmin.id, () => {
                this.setState({error: undefined, loading: false, selectedAdmin: undefined, showDeleteAdmin: false}, () => {this.getAdministrators()});
            }, (error) => {
               this.setState({error: error.message, loading: false, selectedAdmin: undefined, showDeleteAdmin: false}, () => {this.getAdministrators()});
            });
        });
    }

    renderTableHead() {
        return (
            <tr>
                <th className="thead-title" scope="col">Email</th>
                <th className="thead-title" scope="col">Nom complet</th>
                <th className="thead-title" scope="col">Actions</th>
            </tr>
        );
    }

    renderActions(data) {
        const buttons = []

        buttons.push(<button className="btn btn-outline-danger" title="Supprimer l'administrateur" onClick={() => {this.setState({showDeleteAdmin: true, selectedAdmin: data})}}><i className="far fa-times-circle"></i></button>)
        return (
            <div className="btn-group">
                {buttons}
            </div>
        );
    }
    
    renderTableBody() {
        return (
            this.state.administrators.map(administrator => {
                return (
                    <tr key={administrator.id}>
                        <td>{administrator.email_address}</td>
                        <td>{administrator.first_name} {administrator.last_name}</td>
                        <td style={{textAlign: 'center'}}>{this.renderActions(administrator)}</td>
                    </tr>
                )
            })
        )
    }

    renderHeader() {
        return (
            <div>
                <button className="btn btn-outline-primary" onClick={() => {this.setState({showAddAdminModal: true})}}>
                   <i className="fas fa-plus"></i> Ajouter un administrateur
                </button>
                <AddAdministratorModal active={this.state.showAddAdminModal} onClose={() => {this.setState({showAddAdminModal: false}, () => {this.getAdministrators()})}}/>
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }
        if (!this.state.administrators || this.state.administrators.length === 0) {
            return (
                <div className="table-container">
                    {this.renderHeader()}
                    <p> Aucun administrateur n'est disponible </p>
                </div>
            )
        }
        return (
            <div>
                {this.renderHeader()}
                <table className="table table-container">
                    <thead className="thead-light">
                        {this.renderTableHead()}
                    </thead>
                    <tbody>
                        {this.renderTableBody()}
                    </tbody>
                    <ConfirmationModal
                        active={this.state.showDeleteAdmin}
                        variant={"danger"}
                        title="Êtes-vous sûr de vouloir supprimer cet administrateur ?"
                        content={"Il n'aura plus accès à club-manager"}
                        onClose={() => {this.setState({showDeleteAdmin: false, selectedAdmin: undefined})}}
                        execute={() => {this.deleteAdministrator()}}
                    />
                </table>
            </div>
        )
    }
}

export default AdministratorsList;