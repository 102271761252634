import React, { Component } from 'react';

export default class    Select extends Component {

    render() {
        const options = [];

        if (this.props.default === undefined || this.props.default) {
            options.push(<option value={-1}>Choisir une option</option>);
        }
        this.props.options.forEach(option => {
            options.push(<option value={option.value}>{option.display_name}</option>);
        });
        return (
            <select className="form-control" value={this.props.selected} onChange={(e) => {this.props.onChange(e.target.value)}}>
                {options}
            </select>
        )
    }
}