import { ApiService } from "./api_service";
import axios from 'axios';

export class FileService extends ApiService {

    static  getFiles(memberId, callback, callback_error) {
        this.executeGet(`customers/${memberId}/files`, callback, callback_error);
    }

    static createFile(customerId, name, file, callback, callback_error) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append("customer_file", file);
        formData.append("name", name);
        axios.post(this.apiUrl + `/customers/${customerId}/file`, formData, config)
            .then((response) =>
                this.checkResponseSuccess(response, (data) => {
                    callback();
                }, (data) => {
                    callback_error(new Error(data.error));
                }), callback_error);
    }

    static  deleteFile(customerId, fileId, callback, callback_error) {
        this.executeDelete(`customers/${customerId}/file/${fileId}`, {}, callback, callback_error);
    }


    static renderMemberProfilePicture(member) {
        if (member.picture_url.startsWith("https://") || member.picture_url.startsWith("/")) {
            return member.picture_url;
        }
        return this.generateFileUrl(member.picture_url);
    }

    static  generateFileUrl(path) {
        return `${this.apiUrl}/${path}?token=${localStorage.getItem("token")}`;
    }
}
