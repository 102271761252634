import React, { Component } from 'react';
import { ImagesService } from '../../../../services/images_service';
import { NewsService } from '../../../../services/news_service';
import { NewsForm } from './form';

export class NewsCreator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            error: undefined
        }
    }

    createNews(formValues) {
        this.setState({isLoading: true}, () => {

            ImagesService.uploadPicture(formValues.picture, (picture) => {
                NewsService.createNews({
                    title: formValues.title,
                    content: formValues.content,
                    thumbnail_id: picture.fileId
                }, () => {
                    this.setState({isLoading: false}, () => this.props.history.push('/website/news'))
                }, (error) => {
                    this.setState({isLoading: false, error: error});
                })
            }, (error) => {
                this.setState({isLoading: false});
            });
        });
    }

    isFormCompleted(formValues) {
        var completed = true;

        Object.keys(formValues).forEach(key => {
            if (formValues[key] === undefined || formValues[key] === "") {
                completed = false;
            }
        });
        return completed;
    }

    render() {
        return (
            <div>
                <h1> Créer un article </h1>
                <NewsForm isLoading={this.state.isLoading} submitForm={(formValues) => this.createNews(formValues)} isFormCompleted={(formValues) => this.isFormCompleted(formValues)}/>
            </div>
        )
    }
} 