import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { HtmlEditor } from '../../../common/html_editor';

export class NewsForm extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            formValues: {
                title: props.title,
                picture: undefined,
                content: props.content
            }
        };
    }

    renderCreateButton() {
        const buttonTitle = this.props.submitButtonTitle || "Créer l'article"
        if (this.props.isLoading) {
            return (
                <Button variant="primary" disabled={true}>
                    <i className="fa fa-circle-o-notch fa-spin"></i> {buttonTitle}
                </Button>
            )
        }
        else {
            const { formValues } = this.state;

            return (
                <Button variant="primary" disabled={!this.props.isFormCompleted(formValues)} onClick={() => this.props.submitForm(formValues)}>
                    {buttonTitle}
                </Button>

            )
        }
    }

    updateFormValue(key, value) {
        const { formValues } = this.state;

        formValues[key] = value;

        this.setState({formValues: formValues});
    }

    render() {
        return (
            <Form>
                <Form.Group controlId="newsTitle">
                    <Form.Label>Titre</Form.Label>
                    <Form.Control type="text" placeholder="Titre de l'article" onChange={(e) => this.updateFormValue("title", e.target.value)} value={this.state.formValues.title}/>
                    <Form.Text className="text-muted">
                        Ce titre sera également affiché sur la prévisualisation de l'article
                    </Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Image d'affiche</Form.Label>
                    <Form.Control type="file" onChange={(e) => this.updateFormValue("picture", e.target.files[0])}/>
                </Form.Group>

                <Form.Group controlId="newsContent">
                    <Form.Label>Contenu</Form.Label>
                    <HtmlEditor content={this.state.formValues.content}
                                onEditorChange={(content) => this.updateFormValue("content", content)}
                    />
                    <Form.Text className="text-muted">
                        Il est possible d'ajouter des images ou des liens en utilisant les outils d'édition
                    </Form.Text>
                </Form.Group>

                {this.renderCreateButton()}
            </Form>
        )
    }
}