import React, { useRef } from 'react';
import { DocumentsService } from '../../../services/documents_service';
import { DocumentsList } from './documents_list';

import "./documents.css";

const FileUpload = (props) => {
    const inputFile = useRef(null);
  
    const handleFileUpload = e => {
      const { files } = e.target;
      if (files && files.length) {
        const filename = files[0].name;
  
        var parts = filename.split(".");
        const fileType = parts[parts.length - 1];
        console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.
  
        props.uploadFile(files[0]);
      }
    };
  
    const onButtonClick = () => {
      inputFile.current.click();
    };
  
    return (
      <>
        <input
          style={{ display: "none" }}
          // accept=".zip,.rar"
          ref={inputFile}
          onChange={handleFileUpload}
          type="file"
        />
        <button className="btn btn-success" onClick={onButtonClick}>Ajouter un fichier</button>
      </>
    );
  };
  

export class WebsiteDocumentsPagesRouter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            directories: [],
            selectedDirectory: undefined,
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchDirectoriesAndFiles();
    }

    fetchDirectoriesAndFiles() {
        this.setState({files: [], directories: [], isLoading: true}, () => {
            DocumentsService.getDirectories((directories) => {
                DocumentsService.getFiles(this.state.selectedDirectory, (files) => {
                    this.setState({isLoading: false, directories: directories, files: files})
                }, (error) => this.setState({isLoading: false}));
            }, (error) => this.setState({isLoading: false}));
        })
    }

    selectDirectory(id) {
        this.setState({selectedDirectory: id}, () => this.fetchDirectoriesAndFiles())
    }

    renderPath() {
        if (this.state.selectedDirectory !== undefined) {
            const directory = this.state.directories.filter((directory) => directory.id === this.state.selectedDirectory)[0];

            if (directory !== undefined) {
                return (
                    <h3 className="text-bolder"> {directory.display_name} </h3>
                )    
            }    
        }
        return (
            <h3> / </h3>
        )
    }

    renderAction() {
        if (this.state.selectedDirectory === undefined) {
            return (
                <button className="btn btn-primary">Nouveau dossier</button>
            )
        }
        else {
            return (
                <button className="btn btn-danger">Supprimer ce dossier</button>
            )
        }
    }

    uploadFile(file) {
        this.setState({isLoading: true}, () => {
            DocumentsService.uploadFile(file, this.state.selectedDirectory, () => {
                this.fetchDirectoriesAndFiles();
            }, (error) => this.fetchDirectoriesAndFiles())
        });
    }

    render() {
        return (
            <div>
                <h1> Gestion des documents </h1>
                <div className="row">
                    <div className="col">
                        {this.renderPath()}
                    </div>
                    <div className="col-auto directory-actions">
                        {/* {this.renderAction()} */}
                        <FileUpload uploadFile={(file) => this.uploadFile(file)}/>
                    </div>
                </div>
                <DocumentsList reload={() => this.fetchDirectoriesAndFiles()} files={this.state.files} directories={this.state.directories} selectedDirectory={this.state.selectedDirectory} isLoading={this.state.isLoading} selectDirectory={(id) => this.selectDirectory(id)}/>
            </div>
        )    
    }
}