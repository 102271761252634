import React, { Component } from 'react';

import {Area, XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Legend, ResponsiveContainer } from 'recharts';

import './payments_graph.css';
import { StatService } from '../../../services/stat_service';

export class	PaymentsGraph extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: undefined,
			dataFrame: []
		}
	}

    UNSAFE_componentWillReceiveProps(newProps) {
        this.getStats(newProps.activeYear);
    }

	componentDidMount() {
        this.getStats(this.props.activeYear);
    }
    
    getStats(activeYear) {
        StatService.getBillsStats(activeYear, (data) => {
            this.setState({data: {
                bills: data.bills_stats,
                payments: data.payments,
                discounts: data.discounts
            }}, () => this.generateDataFrame())
        }, (error) => {

        })
    }

	componentWillReceiveProps(newProps) {
		this.setState({
			data: newProps.data
		});
		this.generateDataFrame();
    }
    
    entryForMonth(data, month) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].month === month) {
                return data[i];
            }
        }
        return null;
    }

	generateDataFrame() {
		if (this.state.data) {
            const { bills, payments, discounts } = this.state.data;

            var dataFrame = []
            var expectedAmount = 0;
            var paid = 0;
            var discountAmount = 0;
            for (var i = 0; i < bills.length || i < payments.length || i < discounts.length; i++) {
                var month = i < bills.length ? bills[i].month : i < payments.length ? payments[i].month : discounts[i].month;
                
                const paymentsDone = this.entryForMonth(payments, month);
                const expectedBills = this.entryForMonth(bills, month);
                const discountDone = this.entryForMonth(discounts, month);
                if (paymentsDone !== null) {
                    paid += paymentsDone.paid;
                }
                if (expectedBills !== null) {
                    expectedAmount += expectedBills.expected_amount_paid;
                }
                if (discountDone !== null) {
                    discountAmount += discountDone.paid;
                }
                dataFrame.push({name: month, amount: expectedAmount, paid: paid, discount: discountAmount});
            }
			this.setState({dataFrame: dataFrame});
		}
	}

	render() {
		return (
			<div className="graph-container">
				<ResponsiveContainer width="100%" height="100%">
					<AreaChart
					data={this.state.dataFrame}
					margin={{
						top: 10, right: 30, left: 0, bottom: 0,
					}}
						>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name"/>
						<YAxis />
						<Tooltip />
						<Area type="monotone" dataKey="amount" name="Chiffre d'affaire" stroke="#3498db" fill="#3498db" unit="€"/>
						<Area type="monotone" dataKey="paid" name="Payé" stroke="#2c3e50" fill="#34495e" unit="€"/>
						<Area type="monotone" dataKey="discount" name="Réductions" stroke="#d35400" fill="#e67e22" unit="€"/>
						<Legend/>
					</AreaChart>
				</ResponsiveContainer>
			</div>
		)
	}
}
