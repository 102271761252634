import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import Select from './select';
import * as moment from 'moment'
import { BadgeService } from '../../../services/badge_service';

class   AddBadgeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            values: {
                badge_id: (props.badge ? props.badge.badge_id : undefined),
                with_bike: (props.badge ? !!props.badge.with_bike : false),
                created_at: (props.badge ? moment(new Date(props.badge.created_at)).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"))
            }
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({values: {
            badge_id: (newProps.badge ? newProps.badge.badge_id : undefined),
            with_bike: (newProps.badge ? !!newProps.badge.with_bike : false),
            created_at: (newProps.badge ? moment(new Date(newProps.badge.created_at)).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"))
        }});
    }

    handleSelectChange(newValue) {
        const { values } = this.state;

        values.with_bike = JSON.parse(newValue);
        this.setState({values: values});
    }

    handleInputChange(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.value;
        this.setState({values: values});
    }

    renderBody() {
        return (
            <div>
                <div className="form-group">
                    <label>Numéro du badge</label>
                    <input className="form-control" type="text" placeholder="Numéro du badge" name="badge_id" onChange={(e) => {this.handleInputChange(e)}} value={this.state.values.badge_id}/>
                </div>
                <div className="form-group">
                    <label>Option vélo ?</label>
                    <Select default={false} selected={this.state.values.with_bike} options={[{value: false, display_name: "Non"}, {value: true, display_name: "Oui"}]} onChange={(newValue) => {this.handleSelectChange(newValue)}}/>
                </div>
                <div className="form-group">
                    <label>Date de remise</label>
                    <input className="form-control" type="date" name="created_at" value={this.state.values.created_at} onChange={(e) => {this.handleInputChange(e)}} />
                </div>
            </div>
        )
    }

    formIsIncomplete() {
        return this.state.values.badge_id === undefined || this.state.values.badge_id === "" || this.state.values.with_bike === undefined || this.state.values.created_at === undefined;
    }

    addBadgeToCustomer() {
        this.setState({loading: true}, () => {
            if (this.props.badge) {
                BadgeService.editBadge(this.props.member.id, this.props.badge.id, this.state.values, () => {
                    this.setState({loading: false}, () => this.props.onClose());
                }, (error) => {
                    this.setState({loading: false}, () => this.props.onClose());
                })        
            }
            else {
                BadgeService.createBadge(this.props.member.id, this.state.values, () => {
                    this.setState({loading: false}, () => this.props.onClose());
                }, (error) => {
                    this.setState({loading: false}, () => this.props.onClose());
                })    
            }
        });
    }

    render() {
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> {this.props.badge ? "Modifier" : "Ajouter"} un badge </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderBody()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => {this.addBadgeToCustomer()}} disabled={this.formIsIncomplete() || this.state.loading}>
                        {this.props.badge ? "Modifier le badge" : "Ajouter le badge"}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddBadgeModal;