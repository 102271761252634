import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import './add_member.css';
import Select from '../../member_page/components/select';
import * as moment from 'moment'
import { MemberService } from '../../../services/member_service';

class   AddMemberModal extends Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            currentPage: 0,
            pages: [
                {
                    title: "Informations principales",
                    fields: [
                        [{ key: "email", type: "text", placeholder: "E-mail de l'adhérent"}],
                        [{ key: "first_name", type: "text", placeholder: "Prénom"}, { key: "last_name", type: "text", placeholder: "Nom"}],
                        [{ key: "birth_date", type: "date", placeholder: "Date de naissance"}, { key: "job", type: "text", placeholder: "Profession de l'adhérent"}],
                        [{ key: "mobile_phone", type: "text", placeholder: "Numéro de téléphone"}],
                        [{ key: "is_sailor", type: "picker", placeholder: "Régatier ?", onChange: (selectedValue) => {this.isSailorSelected(selectedValue)}, options: [{display_name: "Non", value: 0}, {display_name: "Oui", value: 1}], required: true }],
                        [{ key: "state", type: "picker", placeholder: "Type", onChange: (selectedValue) => {this.isTypeSelected(selectedValue)}, options: [{display_name: "Nouvel adhérent", value: "new_member"}, {display_name: "Adhérent", value: "member"}, {display_name: "Passager", value: "temporary"}, {display_name: "Ancien membre", value: "old_member"}, {display_name: "Member associé", value: "associate_member"}, {display_name: "Membre au bureau", value: "admin_member"}], required: true }]
                    ]
                },
                {
                    title: "Adresse",
                    fields: [
                        [{ key: "address_city", type: "text", placeholder: "Ville" }, { key: "address_postal_code", type: "text", placeholder: "Code postal"}],
                        [{ key: "address_line", type: "text", placeholder: "Numéro et rue"}]
                    ]
                },
                {
                    title: "Image de profil",
                    fields: [
                        [{ key: "profile_picture", type: "file", placeholder: "Choisir une image", "accept": ".jpg, .jpeg, .png", onChange: (e) => {this.handleImageSelected(e)}, required: false}]
                    ]
                }
            ],
            values: {},
            loading: false
        }, () => {
            if (newProps.edit === true && newProps.member !== undefined) {
                const { pages, values } = this.state;

                pages.forEach(page => {
                    page.fields.forEach(fieldRow => {
                        fieldRow.forEach(field => {
                            if (field.type === "date") {
                                const date = new Date(newProps.member[field.key]);
                                values[field.key] = moment(date).format("YYYY-MM-DD");
                            }
                            else {
                                values[field.key] = newProps.member[field.key];
                            }
                        });
                    });
                });
                this.setState({values: values});
            }
        });
    }

    isTypeSelected(value) {
        const { values } = this.state;
        if (value === "-1") {
            values["state"] = undefined;
        }
        else {
            values["state"] = value;
        }
        this.setState({values: values});
    }

    isSailorSelected(value) {
        const { values } = this.state;
        if (value === "-1") {
            values["is_sailor"] = undefined;
        }
        else {
            values["is_sailor"] = value;
        }
        this.setState({values: values});
    }

    nextButtonDisabled() {
        const page = this.state.pages[this.state.currentPage];
        var enabled = true;

        page.fields.forEach(fieldRow => {
            fieldRow.forEach(field => {
                if ((field.required === undefined || field.required) && (this.state.values[field.key] === undefined || this.state.values[field.key] === "")) {
                    enabled = false;
                }    
            });
        });
        return !enabled;
    }

    handleImageSelected(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.files[0];
        this.setState({values: values});
    }

    inputChanged(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.value;
        this.setState({values: values});
    }

    renderForm() {
        const form = this.state.pages[this.state.currentPage];
        const formFields = form.fields;
        var id = 0;
        const formHtml = formFields.map(fieldRow => {
            return (
                <div className="row" key={id++}>
                    {
                    fieldRow.map(field => {
                        if (field.type === "file") {
                            return (
                                <div key={id++} className={"col form-group " + field.extraClassname}>
                                    <input name={field.key} className="form-control" type={field.type} placeholder={field.placeholder} accept={field.accept} onChange={(e) => {field.onChange ? field.onChange(e) : this.inputChanged(e)}}/>
                                </div>
                            );    
                        }
                        else if (field.type === "picker") {
                            return (
                                <div key={id++} className={"col form-group " + field.extraClassname}>
                                    <label>{field.placeholder}</label>
                                    <Select selected={this.state.values[field.key]} options={field.options} onChange={(e) => {field.onChange ? field.onChange(e) : this.inputChanged(e)}}/>
                                </div>
                            );
                        }
                        return (
                            <div key={id++} className={"col form-group " + field.extraClassname}>
                                <input value={this.state.values[field.key] !== undefined ? this.state.values[field.key] : ""} name={field.key} className="form-control" type={field.type} placeholder={field.placeholder} accept={field.accept} onChange={(e) => {field.onChange ? field.onChange(e) : this.inputChanged(e)}}/>
                            </div>
                        );
                    })
                    }
                </div>
            );
        });
        return formHtml;
    }

    renderNextButton() {
        if (this.state.currentPage >= this.state.pages.length - 1) {
            return (
                <Button variant="outline-primary" disabled={this.nextButtonDisabled() || this.state.loading} onClick={() => {this.handleCreate()}}>
                    {this.props.edit ? "Modifier l'adhérent" : "Créer l'adhérent"}
                </Button>
            )
        }
        return (
            <Button variant="outline-primary" disabled={this.nextButtonDisabled()} onClick={() => {this.setState({currentPage: this.state.currentPage + 1})}}>
                Suivant
            </Button>
        )
    }

    renderPrevButton() {
        if (this.state.currentPage > 0) {
            return (
                <Button variant="light" onClick={() => {this.setState({currentPage: this.state.currentPage - 1})}}>
                    Précédent
                </Button>
            );
        }
        return (
            <Button variant="light" onClick={this.props.onClose}>
                Annuler
            </Button>
        );
    }

    updateProfilePicture(customerId) {
        if (this.state.values.profile_picture) {
            MemberService.uploadPicture(customerId, this.state.values.profile_picture, () => {
                this.setState({loading: false}, () => this.props.onClose())
            }, (err) => {
                this.setState({loading: false});
            });
        }
        else {
            this.setState({loading: false}, () => this.props.onClose());
        }
    }

    handleCreate() {
        this.setState({loading: true}, () => {
            if (this.props.member) {
                MemberService.updateMember(this.props.member.id, this.state.values, () => {
                    this.updateProfilePicture(this.props.member.id);
                }, () => {
                    this.setState({loading: false});
                })
            }
            else {
                MemberService.createMember(this.state.values, (success, customerId) => {
                    this.updateProfilePicture(customerId);
                }, (err) => {
                    this.setState({loading: false});
                });
            }
        });
    }

    
    render() {
        if (this.state["currentPage"] === undefined) {
            return (<div></div>)
        }
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> {this.state.pages[this.state.currentPage].title} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderForm()}
                </Modal.Body>
                <Modal.Footer>
                    <p className="step-count"> Etape {this.state.currentPage + 1} / {this.state.pages.length} </p>
                    {this.renderPrevButton()}
                    {this.renderNextButton()}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddMemberModal;