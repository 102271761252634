import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';

import "./sidenav.css";
import { HomeButton, SignoutButton, MembersButton, HarborButton, ConfigButton, StatsButton, WebsiteButton } from './components/buttons';
import { ApiService } from '../../services/api_service';
import { AdminService } from '../../services/admin_service';

class Sidenav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectToReferee: false
        }
    }

    renderUserInfos() {
        const user = ApiService.getUserInfos();

        if (user) {
            return (
                <p>{user.first_name} {user.last_name}</p>
            );
        } else {
            return <></>
        }
    }

    isActive(path) {
        const { location } = this.props;

        if ((path !== "/" && location.pathname.startsWith(path)) || (location.pathname === path)) {
            return true;
        }
        return false;
    }

    disconnectUser() {
        AdminService.disconnect();
        this.props.history.push("/login");
    }

    render() {
        return (
            <div id="sidenav" className={(this.props.hover ? "expanded" : "")} >
                <div id="header">
                    <div id="title">
                        <img className="profile-picture" src={ApiService.getUserInfos().picture_url} alt={"User's profile"} />
                        <div id="account">
                            {this.renderUserInfos()}
                        </div>
                    </div>
                </div>
                <ListGroup as="ul" id="links_list">
                    <HomeButton active={this.isActive("/")}/>
					<MembersButton active={this.isActive("/members")} />
                    <HarborButton active={this.isActive("/harbor")} />
                    <ConfigButton active={this.isActive("/administration")} />
                    <StatsButton active={this.isActive("/stats")} />
                    <WebsiteButton active={this.isActive("/website")} />
                </ListGroup>
                <div className="d-flex align-items-end flex-column">
                    <SignoutButton onClick={() => {this.disconnectUser()}}/>
                </div>
            </div>
        );
    }
}

export default withRouter(Sidenav);
