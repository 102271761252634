import React, { Component } from 'react';
import './login_page.css';
import md5 from 'md5';
import { ErrorNotification } from '../containers/notifications';
import { AdminService } from '../../services/admin_service';

class	LoginPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			form: {
				email: {
					value: undefined,
					error: undefined,
					placeholder: "Votre adresse email",
					title: "Adresse Email",
					type: "email"
				},
				password: {
					value: undefined,
					error: undefined,
					placeholder: "Votre mot de passe",
					title: "Mot de passe",
					type: "password"
				}
			},
			loading: false,
			errorNotification: <></>
		}
	}

	renderInput(name) {
		return (
			<div className="form-group">
				<label> {this.state.form[name].title} <p className="error-text">{this.state.form[name].error}</p> </label>
				<input
					className="form-control"
					type={this.state.form[name].type}
					name={name}
					placeholder={this.state.form[name].placeholder}
					onChange={(e) => {this.inputChange(e)}}
					/>
			</div>
		)
	}

	inputChange(e) {
		const { form } = this.state;
		const targetInputData  = form[e.target.name];
		
		if (e.target.value) {
			targetInputData.value = e.target.value;
			targetInputData.error = undefined;
		}
		else {
			targetInputData.error = "Champ obligatoire"
			targetInputData.value = undefined;
		}
		form[e.target.name] = targetInputData;
		this.setState({
			form: form
		});
	}

	onConnect() {
		this.setState({
			loading: true
		}, () => {
			AdminService.login(this.state.form.email.value, md5(this.state.form.password.value), (success, data) => {
				if (success) {
					this.setState({loading: false}, () => {
						this.props.history.push("/");
					});
				}
				else {
					this.setState({loading: false, errorNotification: <ErrorNotification errorMessage={data.error}/>});
				}
			}, (error) => {
				this.setState({loading: false, errorNotification: <ErrorNotification errorMessage={"Impossible de se connecter"}/>});
			});
		});
	}

	isDisabled() {
		return (this.state.form.email.value === undefined || this.state.form.password.value === undefined);
	}

	renderButton() {
		if (this.state.loading) {
			return (
				<button className="btn btn-primary loading"><i className="fa fa-circle-o-notch fa-spin"></i><span className="btn-title">Connexion</span></button>
			)
		}
		return (
			<button className="btn btn-primary" disabled={this.isDisabled()}onClick={() => {this.onConnect()}}>Connexion</button>
		);
	}

	render() {
		return (
			<div className="login-page">
				<div className="row h-100">
					<div className="col-md-4 col-12 offset-md-8 login-form-container">
						<div className="login-form">
							<div>
								<h1 className="login-title"> CNG - Club Manager </h1>
								{this.renderInput("email")}
								{this.renderInput("password")}
								<div className="form-group connection-button-container">
									{this.renderButton()}
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.errorNotification}
			</div>
		)
	}
}

export default LoginPage;