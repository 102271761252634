import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import Select from './select';
import { ServiceManager } from '../../../services/service_manager';

class   AddBoatModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: undefined,
            services: [],
            selectedService: "-1",
            availableYears: [],
            selectedYear: "-1",
            years: [],
            discount: 0
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.getAvailableYears();
    }

    getAvailableYears() {
        this.setState({loading: true}, () => {
            ServiceManager.getYears((years) => {
                if (years.length > 0) {
                    this.setState({error: undefined, loading: false, years: years, selectedYear: years[0].active_year}, () => {this.retrieveServiceList()});
                }
                else {
                    this.setState({error: "Aucun service n'est disponible.", loading: false, years: years, selectedYear: "-1"}, () => {this.retrieveServiceList()});
                }
            }, (error) => {
                this.setState({error: error.message, loading: false});
            });
        });
    }

    retrieveServiceList() {
        this.setState({loading: true}, () => {
            ServiceManager.getServicesList(this.state.selectedYear, (services) => {
                this.setState({services: services, selectedService: "-1", loading: false});
            }, (error) => {
                this.setState({loading: false, error: error.message});
            });    
        });
    }

    renderLoader() {
        return (
            <div className="loading-container">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    renderPrice() {
        if (this.state.selectedService !== "-1") {
            var price = 0;

            this.state.services.forEach(service => {
                if (service.id === parseInt(this.state.selectedService)) {
                    price = service.price - this.state.discount;
                }
            })
            return (
                <div className="form-group">
                    <p>Prix: <span> {price} </span> €</p>
                </div>
            )
        }
        else {
            return (<></>);
        }
    }

    renderYearSelector() {
        const options = this.state.years.map(year => {
            return ({value: year.active_year, display_name: year.active_year});
        });
        return (
            <div>
                <div className="form-group">
                    <label>Choix de l'année</label>
                    <Select selected={this.state.selectedYear} default={false} options={options} onChange={(year) => {this.setState({selectedYear: year}, () => {this.retrieveServiceList()})}}/>
                </div>
            </div>
        );    
    }

    renderBody() {
        if (this.state.loading) {
            return (this.renderLoader());
        }
        else {
            if (this.state.selectedYear === "-1") {
                return this.renderYearSelector();
            }
            const options = this.state.services.map(service => {
                return ({value: service.id, display_name: `${service.name} - ${service.price}€`});
            });
            return (
                <div>
                    {this.renderYearSelector()}
                    <div className="form-group">
                        <label>Choix du service à facturer</label>
                        <Select options={options} onChange={(service) => {this.setState({selectedService: service})}}/>
                    </div>
                    <div className="form-group">
                        <label> Réduction (€) </label>
                        <input className="form-control" type="number" step="0.01" placeholder="Montant en €" onChange={(e) => {this.setState({discount: e.target.value})}}/>
                    </div>
                    {this.renderPrice()}
                </div>
            );
        }
    }

    addServiceToCustomer() {
        this.setState({loading: true}, () => {
            ServiceManager.addServiceToCustomer(this.state.selectedService, this.state.discount, this.props.member.id, () => {
                this.setState({loading: false}, () => {
                    this.props.onClose();
                });
            }, (error) => {
                this.setState({loading: false, error: error.message});
            })
        });
    }

    formIsIncomplete() {
        return (this.state.selectedService === "-1");
    }

    render() {
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> Ajouter un service </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderBody()}
                    <p className="text-danger">{this.state.error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => {this.addServiceToCustomer()}} disabled={this.formIsIncomplete() || this.state.loading}>
                        Ajouter le service
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddBoatModal;