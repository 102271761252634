import Axios from "axios";
import { ApiService } from "./api_service";

export class DocumentsService extends ApiService {
    static getDirectories(callback, callback_error) {
        this.executeGet('files/directories', callback, callback_error);
    }

    static getFiles(parent, callback, callback_error) {
        if (parent === undefined) {
            this.executeGet(`files`, callback, callback_error);
        }
        else {
            this.executeGet(`files?directoryId=${parent}`, callback, callback_error);
        }
    }

    static uploadFile(file, parent, callback, callback_error) {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const formData = new FormData();
        formData.append("public_file", file);
        if (parent !== undefined) {
            formData.append("parent", parent);
        }
        formData.append("display_name", file.name)
        Axios.put(this.apiUrl + `/files`, formData, config)
            .then((response) => {
				if (response.data["success"] === true) {
					callback(response.data.data);
				}
				else {
					this.handleError(new Error(response.data.error.message), callback_error);
				}
            })
    }

    static deleteFile(fileId, callback, callback_error) {
        this.executeDelete(`files/${fileId}`, {}, callback, callback_error)
    }

    static deleteDirectory(directoryId, callback, callback_error) {
        this.executeDelete(`files/directory/${directoryId}`, {}, callback, callback_error)
    }

    static renderUrl(fileUrl) {
        return `${ApiService.apiUrl}/${fileUrl}`
    }
}