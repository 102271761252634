import React, { Component } from 'react';

import { Container } from '../../containers/container';

import './harbor.css';
import Select from '../../member_page/components/select';
import { BoatService } from '../../../services/boat_service';

const	isOccupied = (pontoonId, placeId, parkedBoats) => {
	let found = undefined;
	parkedBoats.forEach(parkedBoat => {
		if (parkedBoat.pontoon === pontoonId && parkedBoat.quatway_number === placeId) {
			found = parkedBoat;
			return true;
		}
	});
	return found;
}

const	PontoonName = ({pontoonId}) => {
	return (
		<h4 className="pontoon-name">
			{pontoonId}
		</h4>
	);
}

const	renderPlace = (pontoonName, placeId, parkedBoats, className) => {
	const parkedBoat = isOccupied(pontoonName, placeId, parkedBoats);
	if (placeId !== null && parkedBoat) {
		return (
			<div className={"col-5 pontoon-place is-occupied " + className}>
				<p className="owner-name">{parkedBoat.first_name[0]}. {parkedBoat.last_name} </p>
				<p className="boat-name">{parkedBoat.name} ({parkedBoat.length}m)</p>
			</div>
		);
	}
	else if (placeId === -1) {
		return (<div className="col-5"></div>);
	}
	else {
		return (
			<div className={"col-5 pontoon-place " + (placeId === null ? "is-quatway ": "") + className}>
					
			</div>
		)
	}
}

const	renderPontoon = (pontoonName, pontoonDesc, parkedBoats) => {
	const pontoon = pontoonDesc.map(row => {
		return (
			<div className="row pontoon-places-container">
				{renderPlace(pontoonName, row[0], parkedBoats, "left")}
				<div className="col-2 is-quatway quatway-center">
					<p className="place-id left">{row[0] !== -1 ? row[0] : ""}</p>
					<p className="place-id right">{row[1] !== -1 ? row[1] : ""}</p>
				</div>
				{renderPlace(pontoonName, row[1], parkedBoats, "right")}
			</div>
		)
	});
	return (
		<div className="pontoon">
			<div className="pontoons-list">
				{pontoon}
			</div>
			<PontoonName pontoonId={pontoonName}/>
		</div>
	)
}

const	PontoonC = ({parkedBoats}) => {
	const	pontoonDesc = [
		[null, -1],
		[1, -1],
		[3, 2],
		[null, null],
		[5, 4],
		[7, 6],
		[null, null],
		[9, 8],
		[11, 10],
		[null, null],
		[13, 12],
		[15, 14],
		[null, null],
		[17, 16],
		[19, 18],
		[null, null],
		[21, 20],
		[23, 22],
		[null, null],
		[25, 24],
		[27, 26],
		[null, null],
		[29, 28],
		[31, 30],
		[null, null],
		[33, 32],
		[35, 34],
		[null, null],
		[37, 36],
		[39, 38],
		[null, null],
		[41, 40],
		[43, 42],
		[null, null],
		[45, 44],
		[47, 46],
		[null, null],
		[49, 48],
		[51, 50],
		[null, null],
		[53, 52],
		[null, 54],
		[55, null],
		[57, 56],
		[null, 58],
		[59, null],
		[61, 60],
		[null, 62],
		[63, null],
		[65, null],
		[null, null],
		[67, 64]
	]
	pontoonDesc.reverse();
	return (renderPontoon("C", pontoonDesc, parkedBoats));
}

const	PontoonD = ({parkedBoats}) => {
	const	pontoonDesc = [
		[null, null],
		[1, 2],
		[3, 4],
		[null, null],
		[5, 6],
		[7, null],
		[null, 8],
		[9, 10],
		[11, null],
		[null, 12],
		[13, 14],
		[15, null],
		[null, 16],
		[17, 18],
		[19, null],
		[null, 20],
		[21, 22],
		[23, null],
		[null, 24],
		[25, 26],
		[27, null],
		[null, 28],
		[29, 30],
		[31, null],
		[null, 32],
		[33, 34],
		[35, null],
		[null, 36],
		[37, 38],
		[39, null],
		[null, 40],
		[41, 42],
		[null, null],
		[43, 44],
		[45, 46],
		[-1, null],
		[-1, 48],
		[-1, null],
		[-1, 52],
		[null, null]
	]
	pontoonDesc.reverse();
	return (renderPontoon("D", pontoonDesc, parkedBoats));
}

const	PontoonE = ({parkedBoats}) => {
	const	pontoonDesc = [
		[-1, null],
		[-1, 2],
		[-1, 4],
		[-1, null],
		[-1, 6],
		[-1, 8],
		[-1, null],
		[-1, 10],
		[-1, 12],
		[-1, null],
		[-1, 14],
		[-1, 16],
		[-1, null],
		[-1, 18],
		[-1, 20],
		[-1, null],
		[-1, 22],
		[-1, 24],
		[-1, null],
		[-1, 26],
		[-1, 28],
		[-1, null],
		[-1, 30],
		[-1, 32],
		[-1, null],
		[-1, 34],
		[-1, 36],
		[-1, null],
		[-1, 38],
		[-1, 40],
		[-1, null],
		[-1, 42],
		[-1, 44],
		[-1, null],
		[-1, 46],
		[-1, 48],
		[-1, null],
		[-1, 50],
		[-1, 52],
		[-1, null],
		[-1, 54],
		[-1, 56],
		[-1, null],
		[-1, 58],
		[-1, 60],
		[-1, null],
		[-1, 62],
		[-1, null],
		[-1, 64],
		[-1, 66],
		[-1, null],
		[-1, 68],
		[-1, 70],
		[-1, null],
		[-1, 72]

	]
	pontoonDesc.reverse();
	return (renderPontoon("E", pontoonDesc, parkedBoats));
}

const	PontoonF = ({parkedBoats}) => {
	const	pontoonDesc = [
		[null, null],
		[1, 2],
		[3, 4],
		[null, null],
		[5, 6],
		[7, 8],
		[null, null],
		[9, 10],
		[11, 12],
		[null, null],
		[13, 14],
		[15, -1],
		[null, null],
		[17, 16],
		[19, 18],
		[null, null],
		[21, 20],
		[23, 22],
		[null, null],
		[25, 24],
		[27, -1],
		[null, -1],
		[29, -1],
		[31, -1],
		[null, 26],
		[33, null],
		[35, 28],
		[null, 30],
		[37, null],
		[39, 32],
		[null, -1],
		[41, -1],
		[43, 34],
		[null, null],
		[45, 36],
		[47, 38],
		[null, null],
		[49, 40],
		[51, 42],
		[null, null],
		[53, 44],
		[55, 46],
		[null, null],
		[57, 48],
		[-1, 50],
		[-1, null],
		[-1, 52],
		[-1, 54],
		[-1, null],
		[-1, 56],
		[-1, 58],
		[-1, null],
		[-1, 60]
	]
	pontoonDesc.reverse();
	return (renderPontoon("F", pontoonDesc, parkedBoats));
}


export default class Harbor extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			parkedBoats: [],
			selectedYear: new Date().getFullYear(),
			availableYears: [{display_name: new Date().getFullYear()}, {display_name: new Date().getFullYear() - 1}, {display_name: new Date().getFullYear() + 1}]
		}
	}
	
	componentDidMount() {
		this.getParkedBoats();
	}

	getParkedBoats() {
		this.setState({loading: true}, () => {
			BoatService.getParkedBoats(this.state.selectedYear, (parkedBoats) => {
				this.setState({loading: false, parkedBoats: parkedBoats});
			}, (error) => {
			});	
		});
	}

	render() {
		return (
			<Container className="container-fluid">
				<div className="select-group">
					<label>Choix de l'année</label>
					<Select options={this.state.availableYears} default={false} onChange={(newValue) => {this.setState({selectedYear: newValue}, () => {this.getParkedBoats()})}}/>
				</div>
				<div className="row flex-row flex-nowrap pontoons-container">
					<div className="col-auto">
						<PontoonF parkedBoats={this.state.parkedBoats}/>
					</div>
					<div className="col-auto">
						<PontoonE parkedBoats={this.state.parkedBoats}/>
					</div>
					<div className="col-auto">
						<PontoonD parkedBoats={this.state.parkedBoats}/>
					</div>
					<div className="col-auto">
						<PontoonC parkedBoats={this.state.parkedBoats}/>
					</div>
				</div>
			</Container>
		)
	}
}