import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { FileService } from '../../../services/file_service';

class   AddFileModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            values: {
				name: undefined,
				file: undefined
			},
			error: undefined
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({values: {name: undefined, file: undefined}, loading: false});
	}
	
	handleImageSelected(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.files[0];
        this.setState({values: values});
    }

    handleInputChange(e) {
        const { values } = this.state;

        values[e.target.name] = e.target.value;
        this.setState({values: values});
    }

    renderBody() {
        return (
            <div>
                <div className="form-group">
                    <label>Nom du fichier</label>
                    <input className="form-control" type="text" placeholder="Nom du fichier" name="name" value={this.state.values.name} onChange={(e) => {this.handleInputChange(e)}}/>
                </div>
				<div className="col form-group">
					<input name="file" className="form-control" type="file" accept={".jpg, .jpeg, .png, .pdf"} onChange={(e) => {this.handleImageSelected(e)}}/>
				</div>
            </div>
        )
    }

    formIsIncomplete() {
        return this.state.values.name === undefined || this.state.values.name === "" || this.state.values.file === undefined;
    }

    addFileToCustomer() {
		this.setState({loading: true}, () => {
			FileService.createFile(this.props.member.id, this.state.values.name, this.state.values.file, () => {
				this.setState({loading: false}, () => {this.props.onClose()})
			}, (error) => {
				this.setState({loading: false, error: error.message});
			});
		});
    }

    render() {
        return (
            <Modal show={this.props.active} onHide={this.props.onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> Ajouter un document </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderBody()}
					<p className="text-danger">{this.state.error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={() => {this.addFileToCustomer()}} disabled={this.formIsIncomplete() || this.state.loading}>
                        Ajouter le document
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddFileModal;