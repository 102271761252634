import { ApiService } from "./api_service";

export class NewsService extends ApiService {

    static getNews(callback, callback_error) {
        this.executeGet("news", callback, callback_error);
    }

    static getNewsById(newsId, callback, callback_error) {
        this.executeGet(`news/${newsId}`, callback, callback_error);
    } 

    static createNews(newsData, callback, callback_error) {
        this.executePut("news", newsData, callback, callback_error);
    }

    static updateNews(newsId, newsData, callback, callback_error) {
        this.executePost(`news/${newsId}`, newsData, callback, callback_error);
    }

    static publishNews(newsId, callback, callback_error) {
        this.executePost(`news/${newsId}/publish`, {}, callback, callback_error);
    }

    static unpublishNews(newsId, callback, callback_error) {
        this.executePost(`news/${newsId}/unpublish`, {}, callback, callback_error);
    }

    static deleteNews(newsId, callback, callback_error) {
        this.executeDelete(`news/${newsId}`, {}, callback, callback_error);
    }

}