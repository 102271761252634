import { ApiService } from "./api_service";

export class SectionService extends ApiService {

    static getSections(callback, callback_error) {
        this.executeGet("sections", callback, callback_error);
    }

    static updateSection(id, body, callback, callback_error) {
		this.executePost(`sections/${id}`, body, callback, callback_error);
	}
}