import React, { Component } from 'react';
import { NewsService } from '../../../../services/news_service';
import { NewsForm } from './form';
import { ImagesService } from "../../../../services/images_service";

export class NewsEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isUpdating: false,
            notFound: false,
            error: undefined,
            fatalError: undefined,
            news: undefined
        }
    }

    componentDidMount() {
        this.setState({isLoading: true}, () => {
            const { newsId } = this.props.match.params;

            NewsService.getNewsById(newsId, (news) => {
                this.setState({news: news, isLoading: false});
            }, (error) => {
                this.setState({isLoading: false, fatalError: error});
            })
        });
    }

    updateNews(formValues) {
        const { newsId } = this.props.match.params;

        const updateClosure = (thumbnailId) => {
            NewsService.updateNews(newsId, {
                title: formValues.title,
                content: formValues.content,
                thumbnail_id: thumbnailId
            }, () => {
                this.setState({isLoading: false}, () => {
                    this.props.history.push("/website/news")
                });
            }, (error) => {
                this.setState({error: error, isUpdating: false});
            })
        }
        this.setState({isUpdating: true}, () => {
            if (formValues.picture) {
                ImagesService.uploadPicture(formValues.picture, (picture) => {
                    updateClosure(picture.fileId);
                }, (error) => {
                    this.setState({isUpdating: false, error: error});
                })
            }
            else {
                updateClosure(this.state.news.thumbnail_id)
            }
        });
    }

    isFormCompleted(formValues) {
        var completed = true;

        ["title", "content"].forEach(key => {
            if (formValues[key] === undefined || formValues[key] === "") {
                completed = false;
            }
        });
        return completed;
    }

    renderError() {
        if (!this.state.error) {
            return (
                <span></span>
            )
        }
        return (
            <div class="toast show fade error" role="alert" aria-live="assertive" aria-atomic="true" style={{position: "absolute", top: 10, right: 20}}>
                <div class="toast-header">
                    <strong class="mr-auto">Mise à jour de l'article</strong>
                    <button className="btn" onClick={() => this.setState({error: undefined})}>
                        <span aria-hidden="true"><i class="fas fa-times"></i></span>
                    </button>
                </div>
                <div class="toast-body">
                    {this.state.error}
                </div>
            </div>
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="loading-container">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }
        if (this.state.fatalError !== undefined) {
            return (
                <div class="alert alert-danger" role="alert">
                    Impossible de charger cette page ({this.state.fatalError.message})
                </div>
            )
        }
        return (
            <div>
                {this.renderError()}
                <h1> Modifier un article </h1>
                <NewsForm {...this.state.news} submitForm={(formValues) => this.updateNews(formValues)} isLoading={this.state.isUpdating} isFormCompleted={(formValues) => this.isFormCompleted(formValues)} submitButtonTitle={"Mettre à jour l'article"}/>
            </div>
        )
    }
} 