import React, { Component } from 'react';
import { Container } from '../containers/container';
import ServiceList from './components/services_list';
import AdministratorsList from './components/administrators_list';
import './administration_page.css';

class   AdministrationPage extends Component {

    render() {
        return (
            <div>
                <h1> Administration </h1>
                <div className="row">
                    <Container className="col-lg-6">
                        <h4> Services </h4>
                        <ServiceList />
                    </Container>
                    <Container className="col-lg-6 administrator-list">
                        <h4> Administrateurs </h4>
                        <AdministratorsList />
                    </Container>
                </div>
            </div>
        );
    }
}

export default AdministrationPage;