import { ApiService } from "./api_service";

export class LicenseService extends ApiService {

    static getLicenses(memberId, callback, callback_error) {
        this.executeGet(`licenses/${memberId}`, callback, callback_error);    
    }

    static  deleteLicense(licenseId, callback, callback_error) {
        this.executeDelete(`licenses/${licenseId}`, {}, callback, callback_error);
    }

    static  createLicense(customerId, licenseInfos, callback, callback_error) {
        this.executePut(`licenses/${customerId}`, licenseInfos, callback, callback_error);
    }

    static  editLicense(licenseId, licenseInfos, callback, callback_error) {
        this.executePost(`licenses/${licenseId}`, licenseInfos, callback, callback_error);
    }

}